import React, { useState, useRef, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { AutoComplete, Dropdown, message } from 'antd'
import { AiFillDelete, AiOutlineClose, AiOutlineInteraction, AiOutlineMail } from 'react-icons/ai'
import { ImUpload2, ImUserTie } from 'react-icons/im'
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Cookies from 'js-cookie';
import Papa from 'papaparse';
import { GiFirstAidKit } from 'react-icons/gi'
import { FaRegUser } from 'react-icons/fa'
import { TbUserShare } from 'react-icons/tb'
import { MdOutlineUpdate, MdOutlineViewDay } from 'react-icons/md'
import { IoIosArrowDropdown } from 'react-icons/io'
import { BsCloudDownload } from 'react-icons/bs'

function Parasitologyreport() {
  var sno1 = 1
  var sno2 = 1

  const [popup, setpopup] = useState(false)

  const [kitiddd, setkitiddd] = useState("")
  // const [csvFile, setCSVFile] = useState([]);
  const fileInputRef = useRef(null);
  // const [csvData, setCsvData] = useState([]);

  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")


  const [kit, setkit] = useState([])
  const [practitioner, setpractitioner] = useState([])

  // const handleFileUpload = async (event) => {




  //   const files = event.target.files;

  //   console.log(event.target.files)
  //   // console.log(csvFile)
  //   setCSVFile(prevFiles => [...prevFiles, ...Array.from(files)]);





  // };


  // const sortkits = () => {
  //   if (kit.length !== 0) {
  //     const sortedKits = [...kit].sort((a, b) => {
  //       // Check if resultDate is available for both items
  //       if (a.resultDate && b.resultDate) {
  //         const dateA = new Date(a.resultDate.split('/').reverse().join('-'));
  //         const dateB = new Date(b.resultDate.split('/').reverse().join('-'));
  //         return dateB - dateA;
  //       }

  //       // Handle cases where resultDate might be missing
  //       if (!a.resultDate) return 1; // Place a at the end if it has no resultDate
  //       if (!b.resultDate) return -1; // Place b at the end if it has no resultDate

  //       return 0; // If both are missing resultDate, consider them equal
  //     });

  //     setkit(sortedKits);
  //   }
  // }

  // useEffect(() => {
  //   sortkits()
  // }, [kit])


  // const handleFileDrop = (e) => {
  //   e.preventDefault();
  //   const droppedFiles = e.dataTransfer.files;
  //   if (droppedFiles.length > 0) {
  //     setCSVFile(prevFiles => [...prevFiles, ...Array.from(droppedFiles)]);
  //   } else {
  //     alert('Please drop valid files.');
  //   }
  // };





  // const datauploaded = async (e) => {
  //   e.preventDefault()
  //   if (csvFile) {



  //     const hide = message.loading("Action in progress", 0)
  //     await csvFile.map(async (file, index) => {
  //       const ex2 = file.name.split('.')[0]
  //       const newwkid = ex2
  //       const Kittt = newwkid.replace(/\D/g, '');
  //       const kitiddd = parseInt(Kittt)
  //       const ex = file.name.split('.')[1]

  //       const alphabets = newwkid.replace(/[^a-zA-Z]/g, '');

  //       const imgRef = ref(imageDb, `Parasitology/Parasitology result ${alphabets}${kitiddd}.${ex}`)
  //       await uploadBytes(imgRef, file)
  //       const cvUrl = await getDownloadURL(imgRef);





  //       var myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  //       var urlencoded2 = new URLSearchParams();
  //       urlencoded2.append("result", cvUrl);
  //       urlencoded2.append("id", kitiddd);

  //       var requestOptions2 = {
  //         method: 'POST',
  //         headers: myHeaders,
  //         body: urlencoded2,
  //         redirect: 'follow'
  //       };


  //       await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/uploadresultother", requestOptions2)
  //         .then(response => response.json())
  //         .then(result => setkit(result))
  //         .catch(error => console.log('error', error));

  //     })


  //     await getallkits()


  //     await setpopup(false)
  //     await setCSVFile([])


  //     setTimeout(() => {
  //       hide(); // Call hide to stop the loading message
  //       message.success("Action completed successfully");
  //     }, 2000);


  //   }

  //   else { message.error("please upload a file first") }
  // }


  const getallkits = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
      .then(response => response.json())
      .then(result => setkit(result))
  }

  useEffect(() => {


    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }







  }, [])

  const [searchTerm, setSearchTerm] = useState('');
  var options = [];


  const dooption = () => {



    practitioner.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      options.push({ value: value.name })
      options.push({ value: value.email })


    })

    kit.map((value, index) => {

      if (value.otherresults !== "nil" && value.Kittype === "pt") {
        options.push({ value: `${value.prefix}${value.kitid}` })
        // options.push({ value: value.patientName })
        // options.push({ value: value.resultDate })
        // options.push({ value: value.SampleDate })
        // options.push({ value: value.DOB })

      }

    })






  }


  const handleInputChange = async (e) => {
    e.preventDefault()

    const intttt = (searchTerm)

    const filteredSuggestionspra = practitioner.filter((item) =>

      item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



    );



    const Kittt = searchTerm.replace(/\D/g, '');

    const intttt2 = parseInt(Kittt)

    // const  = parseInt(searchTerm)



    const filteredSuggestions = kit.filter((item) => {
      const resullll = item.result;
      if (item.otherresults !== "nil") {
        return (
          item.kitid === intttt2
          || filteredSuggestionspra.map((itemmm) => itemmm._id).includes(item.assignedto.toLowerCase())




        );
      }


    });


    // const intttt = parseInt(searchTerm)

    // // Filter the Kit array based on the user's input
    // const filteredSuggestions = kit.filter((item) =>
    //   item.kitid === intttt
    // );


    // console.log(filteredSuggestions)
    setkit(filteredSuggestions)

    setsearchdone(true)

  };
  const [searchdone, setsearchdone] = useState(false)

  const clearsearch = async () => {
    const hide = message.loading("Action in progress", 0)

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }



    await setsearchdone(false)
    setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  }




  const [popup4, setpopup4] = useState(false)

  const [csvFile, setCSVFile] = useState(null);
  const [csvData, setCsvData] = useState([]);




  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    // console.log(event.target.files)
    // console.log(csvFile)
    setCSVFile(file)

    await Papa.parse(file, {
      complete: (result) => {

        setCsvData(result.data);
        // setCsvData(result.data[0])

      },
      header: true,
      skipEmptyLines: true
    });



  };


  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'text/csv') {
      setCSVFile(droppedFile);
      Papa.parse(droppedFile, {

        complete: (result) => {

          setCsvData(result.data);
          // setCsvData(result.data[0])

        },
        header: true,
        skipEmptyLines: true
      });


    } else {
      alert('Please drop a valid CSV file.');
    }
  };





  const datauploaded = async () => {

    if (csvFile) {
      const hide = message.loading("Action in progress", 0)




      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("file", JSON.stringify(csvData));




      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/uploadresultpraso", requestOptions)
        .then((response) => response.json())
        .then((result) => {


          setkit(result)

        })

      // await getleads()
      await setpopup4(false)
      await setCSVFile(null)
      await setTimeout(() => {
        hide(); // Call hide to stop the loading message
        message.success("Action completed successfully");
      }, 2000);
    }
    else { message.error("please upload a file first") }


  }




  const handleClearInput = () => {
    // setFiles([]); // Clear the files state
    fileInputRef.current.value = ''; // Clear the file input value
    setCSVFile(null)
  };


  const [userinfo, setuserinfo] = useState([])
  const [popupdetails, setpopupdetails] = useState(false)

  const items = [
    {
      key: '1',
      label: (
        <a href={"/dashboard/para-report/" + userinfo._id} target="_blank" rel="noopener noreferrer" onClick={async () => {






        }}>
          View result
        </a>
      ),
      disabled: userinfo.otherresults !== "Pra results",
      icon: <MdOutlineViewDay style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {

          const hide = message.loading("Action in progress", 0)

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", userinfo._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };



          if (userinfo.otherresults === "Pra results") {
            alert("in testing")
          } else {
            const link = document.createElement('a');
            link.href = userinfo.otherresults;
            link.setAttribute('download', `Parasitology result ${userinfo.prefix}${userinfo.kitid}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          // try {
          //   const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions);
          //   const result = await response.json();
          //   setkit2(result);
          //   setResult2(result.result);

          //   // Wait for 2 seconds
          //   await new Promise(resolve => setTimeout(resolve, 500));

          //   // Call the DownloadPDF function after waiting
          //   DownloadPDF(userinfo.patientName);
          // } catch (error) {
          //   console.log('error', error);
          // }



          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);

        }}>
          Download Results
        </a>
      ),
      icon: <BsCloudDownload style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {
          const hide = message.loading("Action in progress", 0)

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", userinfo._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };






          const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresultsothers", requestOptions);
          const result = await response.json();
          await setkit(result);

          await setpopupdetails(false)






          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);
        }}>
          Send Results
        </a>
      ),
      disabled: userinfo.ackresult,

      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

  ];


  return (
    <div>

      <Navbar />

      <div className='deshboardmain'>
        <Sidemanu />
        <div className='profilemainbody'>

          <div className='header'>
            <h1>Parasitology Test Reports</h1>
            {dooption()}
            {searchdone === false && <>
              <form onSubmit={handleInputChange}>
                <AutoComplete
                  type="number"
                  style={{ width: 200 }}
                  options={options}
                  placeholder="Email/Name/Patient Name/Sample Date/upload/practitioner/NamePractitioner Email "
                  filterOption={(inputValue, options) =>
                    options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    //  console.log(kitss) 
                  }
                  onChange={(inputValue) => setSearchTerm(inputValue)}
                />
                <button>Search</button>
              </form>
            </>}
            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
            </>}
          </div>





          {/* <div className='addbutton' style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} ><h1></h1><button onClick={() => setpopup(true)}><ImUpload2 style={{ width: '20px', height: '20px' }} />Upload Result</button></div> */}
          {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
            <div className='addbutton' style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}><h1></h1><button onClick={() => setpopup4(true)} className='button' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><ImUpload2 style={{ width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />Upload CSV File</button></div>
          </>}
          {/* {popup === true && <>
            <div onClick={() => {
              setpopup(false)
              setCSVFile([])
            }
            } className='popupbg'></div>
            <div className='popup'>



              <div className="file-drop-zone"


                onDrop={handleFileDrop}
                onDragOver={(e) => e.preventDefault()}

              >

                <label className="custom-file-upload" >

                  <div className='clickablediv'>


                    <ImUpload2 style={{ width: '100px', height: '100px' }} />
                    <h4>Click or drag file to this area to upload</h4>


                  </div>
                  <input
                    multiple="off"
                    ref={fileInputRef}
                    className='inputbuttontouploadfile'
                    type="file"
                    accept=".pdf"
                    onChange={handleFileUpload}
                  />

                </label>

              </div>

              {csvFile.length > 0 && (
                <div>
                  <h3>Uploaded Files:</h3>
                  <ul>
                    {csvFile.map((file, index) => (
                      <div className="filename" key={index}>
                        <p>{file.name}</p>
                        <AiFillDelete
                          onClick={() => {

                            const updatedFiles = csvFile.filter((_, i) => i !== index);
                            setCSVFile(updatedFiles);

                          }}
                          className="hovar"
                          style={{ width: '30px', height: '30px', color: 'red' }}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
              )}







              <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }} onSubmit={datauploaded}>
              

                <div className='bownpopupbutton' style={{ width: '100%' }}>
                  <button onClick={() => {
                    setpopup(false)
                    setCSVFile([])
                  }
                  } style={{ border: '1px solid red', color: 'black' }} >cancel</button>
                  <button type='submit' style={{ backgroundColor: '#4180b7' }}>Upload</button>
                </div>

              </form>







            </div>
          </>} */}


          {popup4 === true && <>
            <div onClick={() => {
              setpopup4(false)
              setCSVFile(null)
            }
            } className='popupbg'></div>
            <div className='popup'>


              <p style={{ textAlign: 'left' }}><strong>Note:</strong> The uploaded CSV file must contain a proper header with the following fields: <strong>SampleName, Parameter, </strong>and <strong>Phrase</strong>. Ensure that there are no leading or trailing spaces in the header fields. The header row should be the first row in the file. Additionally, the file name will be used as the source of the lead.</p>
              <div className="file-drop-zone"


                onDrop={handleFileDrop}
                onDragOver={(e) => e.preventDefault()}

              >

                <label className="custom-file-upload" >

                  <div className='clickablediv'>


                    <ImUpload2 style={{ width: '100px', height: '100px' }} />
                    <h4>Click or drag file to this area to upload</h4>


                  </div>
                  <input
                    multiple="off"
                    ref={fileInputRef}
                    className='inputbuttontouploadfile'
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                  />

                </label>

              </div>

              <div className='filename'> {csvFile && (<> <p>Selected CSV File: {csvFile.name}</p><AiFillDelete onClick={handleClearInput} className='hovar' style={{ width: '30px', height: '30px', color: 'red' }} /></>)}</div>





              <div className='bownpopupbutton'>
                <button onClick={() => {
                  setpopup4(false)
                  setCSVFile(null)
                }
                } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                <button onClick={datauploaded} style={{ backgroundColor: '#4180b7' }}>Upload</button>




              </div>

            </div>
          </>}




          {popupdetails === true && <>
            <div onClick={() => {
              setpopupdetails(false)
              setCSVFile(null)
            }
            } className='popupbg'></div>
            <div className='popup'>



              <div className='header' >
                <h2>Kits Result Details</h2>
              </div>
              <div className='kitdetailsmain' >




                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <GiFirstAidKit style={{ width: '20px', height: '20px', margin: "0 10px" }} /> KIT ID</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.prefix}{userinfo.kitid} </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    {practitioner.map((item2, index) => (<>

                      {item2._id === userinfo.assignedto && <>

                        <td>{item2.name}</td>


                      </>}</>))}


                  </h3>
                </div>
                <div className='kitdetails'>

                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbUserShare style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    {practitioner.map((item2, index) => (<>

                      {item2._id === userinfo.assignedto && <>



                        <td>{item2.email}</td>
                      </>}</>))}



                  </h3>
                </div>

                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <ImUserTie style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Patient Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.patientName} </h3>
                </div>
                {/* <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <BsCalendar2DateFill style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Date of birth</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.DOB} </h3>
                </div> */}
                {/* <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlinePendingActions style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Sample Date</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.SampleDate} </h3>
                </div> */}
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlineUpdate style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Result upload on</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.resultDate} </h3>
                </div>
                <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <AiOutlineInteraction style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                  <Dropdown menu={{ items: items }} placement="bottomRight" arrow>
                    <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                  </Dropdown>


                </h3></div>





              </div>




              <div className='bownpopupbutton'>
                <button onClick={() => {
                  setpopupdetails(false)

                }
                } style={{ border: '1px solid red', color: 'black' }} >cancel</button>












              </div>

            </div>
          </>}








          {kit.length !== 0 && <>



            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th>S NO.</th>

                  <th>Kit ID</th>



                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>      <th>Practitioner Name</th></>}
                  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>       <th>Practitioner Email</th></>}
                  {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>     <th>Patient Name</th></>}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>


                {kit.map((item, index) =>

                  <>
                    {(item.otherresults !== "nil" && item.Kittype === "pt") && <>










                      <tr>
                        <td>{sno1++} </td>

                        <td>{item.prefix}{item.kitid}</td>






                        {practitioner.map((item2, index) => (<>

                          {item2._id === item.assignedto && <>
                            <td>{item2.name}</td>

                            <td>{item2.email}</td>
                          </>}</>))}


                        {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>
                          <td  >
                            {item.patientName}
                          </td>
                        </>}
                        <td  >
                          <button className='button' onClick={() => {
                            setpopupdetails(true)
                            setuserinfo(item)
                          }}>Detail</button>
                        </td>



                        {/* <td style={{ display: 'flex', justifyContent: 'space-around' }}>

                          {item.ackresult ? (<> <button className='button' style={{ backgroundColor: '#fff', border: "1px solid #4180B7", color: "#4180B7" }} onClick={() => { message.info("The result has already been sent.") }} >Already Sent</button></>) : (<>
                            <button className='button' onClick={async () => {

                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", item._id);

                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };






                              const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresultsothers", requestOptions);
                              const result = await response.json();
                              await setkit(result);


                            }}>Send Result</button>
                          </>)}

                          <button
                            className='button'
                            style={{ backgroundColor: '#6E4E9F' }}
                            onClick={() => {
                              const link = document.createElement('a');
                              link.href = item.otherresults;
                              link.setAttribute('download', `Parasitology result ${item.prefix}${item.kitid}.pdf`);
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                          >
                            Download
                          </button>

                        </td> */}
                      </tr>




                    </>}






                  </>
                )}



              </tbody>
            </table>




          </>}









        </div>


      </div>

    </div>
  )
}

export default Parasitologyreport