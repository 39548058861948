import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import Navbar from '../components/navbar';
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import '../css/shipping.css'
import { message } from 'antd';

function Shippingform() {




    const [btn, setbtn] = useState(true)

    const [Name, setName] = useState("")

    const [Email, setEmail] = useState("")
    const [PlannedShipmentDate, setPlannedShipmentDate] = useState("")
    const [Trackingcode, setTrackingcode] = useState("")
    const [Shipmentcountryoforigin, setShipmentcountryoforigin] = useState("")
    const [SampleNumber, setSampleNumber] = useState("")
    const [Kitcodes, setKitcodes] = useState("")
    const [Notesorremarks, setNotesorremarks] = useState("")


    const uploaddata = async (e) => {

        e.preventDefault()
        setbtn(false)
        const hide = message.loading("Action in progress", 0)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded2 = new URLSearchParams();
        urlencoded2.append("Name", Name);
        urlencoded2.append("Email", Email);
        urlencoded2.append("PlannedShipmentDate", PlannedShipmentDate);
        urlencoded2.append("Trackingcode", Trackingcode);
        urlencoded2.append("Shipmentcountryoforigin", Shipmentcountryoforigin);
        urlencoded2.append("SampleNumber", SampleNumber);
        urlencoded2.append("Kitcodes", Kitcodes);
        urlencoded2.append("Notesorremarks", Notesorremarks);


        var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded2,
            redirect: 'follow'
        };


        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/shippingformdata", requestOptions2)
            .then(response => response.text())
            .then(result => {
                setTimeout(() => {
                    hide(); // Call hide to stop the loading message
                    message.success(result);
                }, 2000);
                setName("")
                setEmail("")
                setPlannedShipmentDate("")
                setTrackingcode("")
                setShipmentcountryoforigin("")
                setSampleNumber("")
                setKitcodes("")
                setNotesorremarks("")
            })
            .catch(error => console.log('error', error));
        await setbtn(true)

    }


    return (<>


        <Navbar />
        <div className='deshboardmain'>
            <div className='popup' style={{ height: 'fit-contant', backgroundColor: '#fdgh',position:'relative',maxHeight:'fit-content',zIndex:'0',top:'0' }}>
                <form className='shippinfform' onSubmit={uploaddata}>
                    <div className='lableee'>
                        <label>Name <span style={{ color: "red" }}>*</span></label>
                        <input onChange={(e) => setName(e.target.value)} value={Name} required placeholder='Name' />
                    </div>

                    <div className='lableee'>
                        <label>Email <span style={{ color: "red" }}>*</span></label>
                        <input onChange={(e) => setEmail(e.target.value)} value={Email} type='email' required placeholder='Email' />
                    </div>

                    <div className='lableee'>
                        <label>Planned Shipment Date</label>
                        <input onChange={(e) => setPlannedShipmentDate(e.target.value)} value={PlannedShipmentDate} type='date' placeholder='Shipment Date' />
                    </div>

                    <div className='lableee'>
                        <label>Tracking code (when available, otherwise please write NA)</label>
                        <input onChange={(e) => setTrackingcode(e.target.value)} value={Trackingcode} placeholder='Tracking code' />
                    </div>

                    <div className='lableee'>
                        <label>Shipment country of origin </label>
                        <input onChange={(e) => setShipmentcountryoforigin(e.target.value)} value={Shipmentcountryoforigin} placeholder='Shipment country of origin' />
                    </div>

                    <div className='lableee'>
                        <label>Number of Samples <span style={{ color: "red" }}>*</span> </label>
                        <input onChange={(e) => setSampleNumber(e.target.value)} value={SampleNumber} required placeholder='Sample Number' />
                    </div>

                    <div className='lableee'>
                        <label>Kit codes <span style={{ color: "red" }}>*</span> </label>
                        <textarea onChange={(e) => setKitcodes(e.target.value)} value={Kitcodes} required placeholder='Kitcodes' />
                    </div>

                    <div className='lableee'>
                        <label>Notes or remarks:</label>
                        <textarea onChange={(e) => setNotesorremarks(e.target.value)} value={Notesorremarks} placeholder='Notes or remarks' />
                    </div>

                    <button disabled={!btn}  style={{ width: "100%" }}>SEND</button>
                </form>



            </div>
        </div>

    </>)
}

export default Shippingform