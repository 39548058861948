import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import { MdOutlineAppRegistration } from 'react-icons/md';
import { message } from 'antd';
import { GiFirstAidKit } from 'react-icons/gi';

function Kitregistration() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const [data, setdata] = useState([])
    const [patient, setpatient] = useState([])
    const [popup, setpopup] = useState(false)


    useEffect(() => {

        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



            var requestOptions = {
                method: 'GET',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getregistration", requestOptions)
                .then(response => response.json())
                .then(result => setdata(result))




        }






    }, [])

    var sno1 = 1
    return (
        <>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <Navbar />
                <div className='deshboardmain'>

                    <Sidemanu />

                    <div className='adminkitmainbody'>

                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><MdOutlineAppRegistration style={{ width: '50px', height: '50px' }} />Kits Registration</h1>
                            {/* {dooption()}
                            {searchdone === false && <>
                                <form onSubmit={handleInputChange}>
                               

                                    <AutoComplete
                                        type="number"
                                        style={{ width: 200 }}
                                        options={options}
                                        placeholder="Search by Kit ID"
                                        filterOption={(inputValue, options) =>
                                            options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            //  console.log(kitss) 
                                        }
                                        onChange={(inputValue) => setSearchTerm(inputValue)}
                                    />
                                    <button>Search</button>
                                </form>
                            </>}
                            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
                            </>} */}
                        </div>


                        {popup === true && <>
                            <div onClick={() => {
                                setpopup(false)

                            }
                            } className='popupbg'></div>
                            <div className='popup'>




                                <div className='header' >
                                    <h2>Patient Details</h2>
                                </div>
                                <div className='kitdetailsmain' >




                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>1 -  KIT ID</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.kitID} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>2 -  Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.name}  {patient.namel} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>3 - Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.email} </h3>
                                    </div>






                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>4 - Gender at birth</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.gender} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>5 - Date of birth</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.birthdate} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>6 - Weight</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.weight}{patient.weightUnit} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>7 - Height</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.height}{patient.heightUnit} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>8 - Mode of your birth</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.birthMode} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>9 - Country of residence</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.countryOfResidence} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>10 - Sleep per day</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.hoursOfSleepPerDay} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>11 - Smoke?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.smoking} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>12 - Alcohol?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.usingAlcohol} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>13 - Live Alone?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.livingAloneInYourLivingSpaceResidence} {patient.withhowmanypeopledoyoulive !== "undefined" && <>{patient.withhowmanypeopledoyoulive}</>} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>14 - Have diabetes</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingDiabetes}{patient.diabetesType !== "undefined" && <> {patient.diabetesType}</>} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>15 - Have kidney disease?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingKidneyDisease} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>16 - Diagnosed with cancer?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.diagnosedWithCancer}{patient.thetypeofcancerandthetreatmentyoureceived !== "undefined" && <>{patient.thetypeofcancerandthetreatmentyoureceived} </>}</h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>17 - Lactose intolerance?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingLactoseIntolerance}{patient.lactoseToleranceTestUsed !== "undefined" && <> {patient.lactoseToleranceTestUsed} </>} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>18 - Have lung disease?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingLungDisease} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>19 - Have liver disease?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingLiverDisease} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>20 - Have heart disease?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingHeartDisease} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>21 - Suffer with migraines?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingMigraine} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>22 - Have gluten sensitivity?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingGlutenSensitivity}{patient.haveYouBeenDiagnosedWithCeliac !== "undefined" && <> {patient.haveYouBeenDiagnosedWithCeliac} </>}</h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>23 - Have thyroid disease?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.havingThyroidDisease} </h3>
                                    </div>
                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>24 - Bowel activity / frequency</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.bowelActivityFrequencyOfGoingToTheToilet} {patient.howManyTimesDoYouGoToTheToilet !== "undefined" && <>{patient.howManyTimesDoYouGoToTheToilet} </>}</h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>25 - Antibiotics in the last six months?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.antibioticsUsageInTheLastSixMonths} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>26 - Use vitamin / multivitamin supplements?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.vitaminMultivitaminSupplementsUsage} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>27 - Probiotic supplements</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.probioticSupplementsUsage} {patient.whatTypeOfProbioticMicroorganismDoYouUse !== "undefined" && <>{patient.whatTypeOfProbioticMicroorganismDoYouUse}</>} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>28 - Diet</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.suitableDiet} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>29 - Bowel syndrome (IBS)</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.irritableBowelSyndromeIBS} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>30 - Bowel disease (IBD)</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.inflammatoryBowelDiseaseIBD} {patient.ibdType !== "undefined" && <>{patient.ibdType}</>}</h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>31 - Products containing artificial sweeteners</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}> {patient.artificialSweeteners !== "undefined" && <> {patient.artificialSweeteners}</>} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>32 - Have skin problems?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.skinProblem} {patient.ciltProbleminiz !== "undefined" && <>{patient.ciltProbleminiz}</>} </h3>
                                    </div>

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>33 - Chronic bowel problems?</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {patient.chronicBowelProblem} {patient.typeOfBowelProblem !== "undefined" && <> {patient.typeOfBowelProblem} </>}</h3>
                                    </div>








                                </div>



                                <div className='bownpopupbutton'>
                                    <button onClick={() => {
                                        setpopup(false)

                                    }
                                    } style={{ border: '1px solid red', color: 'black' }} >cancel</button>




                                    {patient.saved !== true && <>
                                        <button style={{ backgroundColor: '#4180b7' }} onClick={async () => {

                                            const hide = message.loading("Action in progress", 0)

                                            const myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


                                            const urlencoded = new URLSearchParams();

                                            urlencoded.append("_id", patient._id);

                                            urlencoded.append("kitID", patient.kitID);
                                            urlencoded.append("name", patient.name);
                                            urlencoded.append("namel", patient.namel);
                                            urlencoded.append("email", patient.email);


                                            urlencoded.append("antibioticsUsageInTheLastSixMonths", patient.antibioticsUsageInTheLastSixMonths)
                                            urlencoded.append("artificialSweeteners", patient.artificialSweeteners)
                                            urlencoded.append("birthMode", patient.birthMode)
                                            urlencoded.append("birthdate", patient.birthdate)
                                            urlencoded.append("bowelActivityFrequencyOfGoingToTheToilet", patient.bowelActivityFrequencyOfGoingToTheToilet)
                                            urlencoded.append("chronicBowelProblem", patient.chronicBowelProblem)
                                            urlencoded.append("ciltProbleminiz", patient.ciltProbleminiz)
                                            urlencoded.append("countryOfResidence", patient.countryOfResidence)
                                            urlencoded.append("diabetesType", patient.diabetesType)
                                            urlencoded.append("diagnosedWithCancer", patient.diagnosedWithCancer)
                                            urlencoded.append("gender", patient.gender)
                                            urlencoded.append("haveYouBeenDiagnosedWithCeliac", patient.haveYouBeenDiagnosedWithCeliac)
                                            urlencoded.append("havingDiabetes", patient.havingDiabetes)
                                            urlencoded.append("havingGlutenSensitivity", patient.havingGlutenSensitivity)
                                            urlencoded.append("havingHeartDisease", patient.havingHeartDisease)
                                            urlencoded.append("havingKidneyDisease", patient.havingKidneyDisease)
                                            urlencoded.append("havingLactoseIntolerance", patient.havingLactoseIntolerance)
                                            urlencoded.append("havingLiverDisease", patient.havingLiverDisease)
                                            urlencoded.append("havingLungDisease", patient.havingLungDisease)
                                            urlencoded.append("havingMigraine", patient.havingMigraine)
                                            urlencoded.append("havingThyroidDisease", patient.havingThyroidDisease)
                                            urlencoded.append("height", patient.height)
                                            urlencoded.append("heightUnit", patient.heightUnit)
                                            urlencoded.append("hoursOfSleepPerDay", patient.hoursOfSleepPerDay)
                                            urlencoded.append("howManyTimesDoYouGoToTheToilet", patient.howManyTimesDoYouGoToTheToilet)
                                            urlencoded.append("ibdType", patient.ibdType)
                                            urlencoded.append("inflammatoryBowelDiseaseIBD", patient.inflammatoryBowelDiseaseIBD)
                                            urlencoded.append("irritableBowelSyndromeIBS", patient.irritableBowelSyndromeIBS)
                                            urlencoded.append("lactoseToleranceTestUsed", patient.lactoseToleranceTestUsed)
                                            urlencoded.append("livingAloneInYourLivingSpaceResidence", patient.livingAloneInYourLivingSpaceResidence)
                                            urlencoded.append("probioticSupplementsUsage", patient.probioticSupplementsUsage)
                                            urlencoded.append("skinProblem", patient.skinProblem)
                                            urlencoded.append("smoking", patient.smoking)
                                            urlencoded.append("suitableDiet", patient.suitableDiet)
                                            urlencoded.append("typeOfBowelProblem", patient.typeOfBowelProblem)
                                            urlencoded.append("usingAlcohol", patient.usingAlcohol)
                                            urlencoded.append("vitaminMultivitaminSupplementsUsage", patient.vitaminMultivitaminSupplementsUsage)
                                            urlencoded.append("weight", patient.weight)
                                            urlencoded.append("weightUnit", patient.weightUnit)
                                            urlencoded.append("whatTypeOfProbioticMicroorganismDoYouUse", patient.whatTypeOfProbioticMicroorganismDoYouUse)
                                            urlencoded.append("thetypeofcancerandthetreatmentyoureceived", patient.thetypeofcancerandthetreatmentyoureceived)
                                            urlencoded.append("countryid", patient.countryid)
                                            urlencoded.append("withhowmanypeopledoyoulive", patient.withhowmanypeopledoyoulive)



                                            const requestOptions = {
                                                method: "POST",
                                                headers: myHeaders,
                                                body: urlencoded,
                                                redirect: "follow"
                                            };

                                            await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/uploadpataintformdataupload", requestOptions)
                                                .then((response) => response.json())
                                                .then((result) => setdata(result))
                                                .catch((error) => console.error(error));

                                            await setpopup(false)
                                            await setTimeout(() => {
                                                hide(); // Call hide to stop the loading message
                                                message.success("Action completed successfully");
                                            }, 2000);


                                        }}>Save Data</button>
                                    </>}


                                </div>

                            </div>
                        </>}



                        <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>

                            <thead className='tablephead'>
                                <tr>
                                    <th>S NO.</th>
                                    <th>KIT ID</th>
                                    <th>Name</th>
                                    <th>Email</th>


                                    <th>Action</th>
                                </tr>


                            </thead>
                            <tbody>

                                {data.map((item, index) => {
                                    return (<>

                                        <tr>
                                            <td>{sno1++}</td>
                                            <td>{item.kitID}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>

                                                <button className='button' onClick={() => {
                                                    setpatient(item)
                                                    setpopup(true)
                                                }} >Details</button>
                                                {!item.saved ? (<>
                                                    <button className='button' style={{ backgroundColor: '#6E4E9F' }} onClick={async () => {

                                                        const hide = message.loading("Action in progress", 0)

                                                        const myHeaders = new Headers();
                                                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


                                                        const urlencoded = new URLSearchParams();

                                                        urlencoded.append("_id", item._id);

                                                        urlencoded.append("kitID", item.kitID);
                                                        urlencoded.append("name", item.name);
                                                        urlencoded.append("namel", item.namel);
                                                        urlencoded.append("email", item.email);


                                                        urlencoded.append("antibioticsUsageInTheLastSixMonths", item.antibioticsUsageInTheLastSixMonths)
                                                        urlencoded.append("artificialSweeteners", item.artificialSweeteners)
                                                        urlencoded.append("birthMode", item.birthMode)
                                                        urlencoded.append("birthdate", item.birthdate)
                                                        urlencoded.append("bowelActivityFrequencyOfGoingToTheToilet", item.bowelActivityFrequencyOfGoingToTheToilet)
                                                        urlencoded.append("chronicBowelProblem", item.chronicBowelProblem)
                                                        urlencoded.append("ciltProbleminiz", item.ciltProbleminiz)
                                                        urlencoded.append("countryOfResidence", item.countryOfResidence)
                                                        urlencoded.append("diabetesType", item.diabetesType)
                                                        urlencoded.append("diagnosedWithCancer", item.diagnosedWithCancer)
                                                        urlencoded.append("gender", item.gender)
                                                        urlencoded.append("haveYouBeenDiagnosedWithCeliac", item.haveYouBeenDiagnosedWithCeliac)
                                                        urlencoded.append("havingDiabetes", item.havingDiabetes)
                                                        urlencoded.append("havingGlutenSensitivity", item.havingGlutenSensitivity)
                                                        urlencoded.append("havingHeartDisease", item.havingHeartDisease)
                                                        urlencoded.append("havingKidneyDisease", item.havingKidneyDisease)
                                                        urlencoded.append("havingLactoseIntolerance", item.havingLactoseIntolerance)
                                                        urlencoded.append("havingLiverDisease", item.havingLiverDisease)
                                                        urlencoded.append("havingLungDisease", item.havingLungDisease)
                                                        urlencoded.append("havingMigraine", item.havingMigraine)
                                                        urlencoded.append("havingThyroidDisease", item.havingThyroidDisease)
                                                        urlencoded.append("height", item.height)
                                                        urlencoded.append("heightUnit", item.heightUnit)
                                                        urlencoded.append("hoursOfSleepPerDay", item.hoursOfSleepPerDay)
                                                        urlencoded.append("howManyTimesDoYouGoToTheToilet", item.howManyTimesDoYouGoToTheToilet)
                                                        urlencoded.append("ibdType", item.ibdType)
                                                        urlencoded.append("inflammatoryBowelDiseaseIBD", item.inflammatoryBowelDiseaseIBD)
                                                        urlencoded.append("irritableBowelSyndromeIBS", item.irritableBowelSyndromeIBS)
                                                        urlencoded.append("lactoseToleranceTestUsed", item.lactoseToleranceTestUsed)
                                                        urlencoded.append("livingAloneInYourLivingSpaceResidence", item.livingAloneInYourLivingSpaceResidence)
                                                        urlencoded.append("probioticSupplementsUsage", item.probioticSupplementsUsage)
                                                        urlencoded.append("skinProblem", item.skinProblem)
                                                        urlencoded.append("smoking", item.smoking)
                                                        urlencoded.append("suitableDiet", item.suitableDiet)
                                                        urlencoded.append("typeOfBowelProblem", item.typeOfBowelProblem)
                                                        urlencoded.append("usingAlcohol", item.usingAlcohol)
                                                        urlencoded.append("vitaminMultivitaminSupplementsUsage", item.vitaminMultivitaminSupplementsUsage)
                                                        urlencoded.append("weight", item.weight)
                                                        urlencoded.append("weightUnit", item.weightUnit)
                                                        urlencoded.append("whatTypeOfProbioticMicroorganismDoYouUse", item.whatTypeOfProbioticMicroorganismDoYouUse)
                                                        urlencoded.append("thetypeofcancerandthetreatmentyoureceived", item.thetypeofcancerandthetreatmentyoureceived)
                                                        urlencoded.append("countryid", item.countryid)
                                                        urlencoded.append("withhowmanypeopledoyoulive", item.withhowmanypeopledoyoulive)



                                                        const requestOptions = {
                                                            method: "POST",
                                                            headers: myHeaders,
                                                            body: urlencoded,
                                                            redirect: "follow"
                                                        };

                                                        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/uploadpataintformdataupload", requestOptions)
                                                            .then((response) => response.json())
                                                            .then((result) => setdata(result))
                                                            .catch((error) => console.error(error));
                                                        await setTimeout(() => {
                                                            hide(); // Call hide to stop the loading message
                                                            message.success("Action completed successfully");
                                                        }, 2000);
                                                    }}>Save Data</button>
                                                </>) : (<>


                                                    <button className='button' style={{ backgroundColor: '#fff', border: "1px solid #6E4E9F", color: "#6E4E9F" }}>Already Saved</button>

                                                </>)}

                                            </td>


                                        </tr>
                                    </>)
                                })}


                            </tbody>
                        </table>


                    </div>
                </div>
            </>}
        </>
    )
}

export default Kitregistration
