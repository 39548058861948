import React, { useState, useRef, useEffect } from 'react'
import Navbar from '../components/navbar';
import "../css/adminreport.css"
import Sidemanu from '../components/sidemanu';
import "../css/adminkit.css"
import { ImUpload2, ImUserTie } from "react-icons/im"
import { BsCalendar2DateFill, BsCloudDownload } from "react-icons/bs"
import { AiFillDelete, AiOutlineClose, AiOutlineInteraction } from "react-icons/ai"
import Papa from 'papaparse';
import { AutoComplete, Dropdown, message } from 'antd';
import { CSVLink } from 'react-csv';
import { FaDownload, FaRegUser } from "react-icons/fa"
import html2pdf from 'html2pdf.js';
import Cookies from 'js-cookie';
import { AiOutlineMail } from "react-icons/ai"
import { GiFirstAidKit } from 'react-icons/gi';
import { IoIosArrowDropdown } from 'react-icons/io';
import { MdOutlinePendingActions, MdOutlineUpdate, MdOutlineViewDay } from 'react-icons/md';
import { TbUserShare } from 'react-icons/tb';



function Adminreport() {


  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")


  const [popup, setpopup] = useState(false)

  const fileInputRef = useRef(null);

  const [csvFile, setCSVFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [kit, setkit] = useState([])
  const [practitioner, setpractitioner] = useState([])



  const [kit2, setkit2] = useState([]);

  const [Result2, setResult2] = useState([]);
  const [Result222, setResult222] = useState([]);




  const sortkits = () => {
    if (kit.length !== 0) {
      const sortedKits = [...kit].sort((a, b) => {
        // Check if resultDate is available for both items
        if (a.resultDate && b.resultDate) {
          const dateA = new Date(a.resultDate.split('/').reverse().join('-'));
          const dateB = new Date(b.resultDate.split('/').reverse().join('-'));
          return dateB - dateA;
        }

        // Handle cases where resultDate might be missing
        if (!a.resultDate) return 1; // Place a at the end if it has no resultDate
        if (!b.resultDate) return -1; // Place b at the end if it has no resultDate

        return 0; // If both are missing resultDate, consider them equal
      });

      setkit(sortedKits);
    }
  }

  useEffect(() => {
    sortkits()
  }, [kit])






  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    // console.log(event.target.files)
    // console.log(csvFile)
    setCSVFile(file)

    await Papa.parse(file, {
      complete: (result) => {





        setCsvData(result.data)

      },
      header: true,
      skipEmptyLines: true
    });



  };


  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'text/csv') {
      setCSVFile(droppedFile);
      Papa.parse(droppedFile, {
        complete: (result) => {

          setCsvData(result.data)

        },
        header: true,
        skipEmptyLines: true
      });


    } else {
      alert('Please drop a valid CSV file.');
    }
  };









  const datauploaded = () => {

    if (csvFile) {
      const hide = message.loading("Action in progress", 0)


      const datacalculation = (index) => {


        const Berley = 1 * parseFloat(csvData[index][' "AU - Barley Flour"'])
        const GlutenGliadin = 1 * parseFloat(csvData[index][' "AU - Gluten"'])
        const Rye = 0.86 * parseFloat(csvData[index][' "AU - Gluten"'])
        const Wheat = 1 * parseFloat(csvData[index][' "AU - Wheat"'])
        const BrownRice = 1 * parseFloat(csvData[index][' "AU - Rice"'])
        const Buckwheat = 0.94 * parseFloat(csvData[index][' "AU - Wheat"'])
        const Oat = 1 * parseFloat(csvData[index][' "AU - Oat"'])
        const Spelt = 0.74 * parseFloat(csvData[index][' "AU - Wheat"'])
        const WhiteRice = 1.27 * parseFloat(csvData[index][' "AU - Rice"'])
        const Quinoa = 0.66 * parseFloat(csvData[index][' "AU - Spinach"'])
        const Apple = 1 * parseFloat(csvData[index][' "AU - Apple"'])
        const Apricot = 1 * parseFloat(csvData[index][' "AU - Apricot"'])
        const Banana = 1 * parseFloat(csvData[index][' "AU - Banana"'])
        const Blackberry = 1 * parseFloat(csvData[index][' "AU - Blackberry"'])
        const Blueberry = 1 * parseFloat(csvData[index][' "AU - Blueberry"'])
        const BlackOlive = 1 * parseFloat(csvData[index][' "AU - Olive"'])
        const CantaloupeMelon = 1 * parseFloat(csvData[index][' "AU - Cantaloupe Melon"'])
        const Cherry = 1 * parseFloat(csvData[index][' "AU - Cherry"'])
        const Cranberry = 1 * parseFloat(csvData[index][' "AU - Cranberry"'])
        const Date = 0.71 * parseFloat(csvData[index][' "AU - Apricot"'])
        const Fig = 0.34 * parseFloat(csvData[index][' "AU - Apricot"'])
        const GrapeRed = 1 * parseFloat(csvData[index][' "AU - Grape - Red"'])
        const GrapeWhite = 1 * parseFloat(csvData[index][' "AU - Grape - white"'])
        const Grapefruit = 1 * parseFloat(csvData[index][' "AU - Grapefruit"'])
        const HoneydewMelon = 0.88 * parseFloat(csvData[index][' "AU - Cantaloupe Melon"'])
        const Lemon = 1 * parseFloat(csvData[index][' "AU - Lemon"'])
        const Lime = 0.74 * parseFloat(csvData[index][' "AU - Lemon"'])
        const Lychee = 0.21 * parseFloat(csvData[index][' "AU - Mango"'])
        const Mango = 1 * parseFloat(csvData[index][' "AU - Mango"'])
        const Nectarine = 0.89 * parseFloat(csvData[index][' "AU - Orange"'])
        const GreenOlive = 0.72 * parseFloat(csvData[index][' "AU - Olive"'])
        const Orange = 1 * parseFloat(csvData[index][' "AU - Orange"'])
        const Peach = 1 * parseFloat(csvData[index][' "AU - Peach"'])
        const Pear = 1 * parseFloat(csvData[index][' "AU - Pear"'])
        const PersianMelon = 0.31 * parseFloat(csvData[index][' "AU - Cantaloupe Melon"'])
        const Pineapple = 1 * parseFloat(csvData[index][' "AU - Pineapple"'])
        const Raisins = 0.79 * parseFloat(csvData[index][' "AU - Grape - Red"'])
        const Pomegranate = 0.46 * parseFloat(csvData[index][' "AU - Apricot"'])
        const Raspberry = 1 * parseFloat(csvData[index][' "AU - Raspberry"'])
        const Strawberry = 1 * parseFloat(csvData[index][' "AU - Strawberry"'])
        const Sultana = 1.23 * parseFloat(csvData[index][' "AU - Grape - white"'])
        const Tangerine = 0.9 * parseFloat(csvData[index][' "AU - Orange"'])
        const Watermelon = 1 * parseFloat(csvData[index][' "AU - Watermelon"'])
        const Asparagus = 0.83 * parseFloat(csvData[index][' "AU - Garden Pea"'])
        const Avocado = 1 * parseFloat(csvData[index][' "AU - Avocado"'])
        const Beetroot = 0.71 * parseFloat(csvData[index][' "AU - Spinach"'])
        const BokChoy = 0.65 * parseFloat(csvData[index][' "AU - Sheep Milk"'])
        const Broccoli = 1 * parseFloat(csvData[index][' "AU - Broccoli"'])
        const BrusselSprouts = 0.61 * parseFloat(csvData[index][' "AU - Mustard"'])
        const Carrot = 1 * parseFloat(csvData[index][' "AU - Carrot"'])
        const Cassava = 0.47 * parseFloat(csvData[index][' "AU - Sweet Potato"'])
        const Cauliflower = 1 * parseFloat(csvData[index][' "AU - Cauliflower"'])
        const Celery = 0.51 * parseFloat(csvData[index][' "AU - Carrot"'])
        const ChickPea = 1 * parseFloat(csvData[index][' "AU - Chick Pea"'])
        const CowPea = 0.61 * parseFloat(csvData[index][' "AU - Garden Pea"'])
        const Ccumber = 1 * parseFloat(csvData[index][' "AU - Cucumber"'])
        const FeildMushroom = 1 * parseFloat(csvData[index][' "AU - Mushroom"'])
        const GardenPea = 1 * parseFloat(csvData[index][' "AU - Garden Pea"'])
        const GreenPepper = 1 * parseFloat(csvData[index][' "AU - Green Pepper"'])
        const GreenCabbage = 1 * parseFloat(csvData[index][' "AU - Cabbage"'])
        const IcebergLettuce = 1 * parseFloat(csvData[index][' "AU - Lettuce"'])
        const Kale = 1.27 * parseFloat(csvData[index][' "AU - Sheep Milk"'])
        const Maize = 1.36 * parseFloat(csvData[index][' "AU - Black Tea"'])
        const OysterMushroom = 0.73 * parseFloat(csvData[index][' "AU - Mushroom"'])
        const Parsnip = 0.74 * parseFloat(csvData[index][' "AU - Carrot"'])
        const PortebelloMushroom = 0.66 * parseFloat(csvData[index][' "AU - Mushroom"'])
        const Pumpkin = 0.67 * parseFloat(csvData[index][' "AU - Cucumber"'])
        const Radish = 0.64 * parseFloat(csvData[index][' "AU - Broccoli"'])
        const RedCabbage = 0.57 * parseFloat(csvData[index][' "AU - Cabbage"'])
        const RedOnion = 1.26 * parseFloat(csvData[index][' "AU - Onion"'])
        const RedPepper = 0.71 * parseFloat(csvData[index][' "AU - Green Pepper"'])
        const Rocket = 0.68 * parseFloat(csvData[index][' "AU - Sheep Milk"'])
        const ShiitakeMushroom = 0.71 * parseFloat(csvData[index][' "AU - Mushroom"'])
        const Spinach = 1 * parseFloat(csvData[index][' "AU - Spinach"'])
        const SpringOnion = 0.64 * parseFloat(csvData[index][' "AU - Onion"'])
        const SugarSnapPeas = 1.27 * parseFloat(csvData[index][' "AU - Chick Pea"'])
        const SwedishTurnipSwede = 1 * parseFloat(csvData[index][' "AU - Turnip"'])
        const Sweetcorn = 1 * parseFloat(csvData[index][' "AU - Corn"'])
        const SweetPotato = 1 * parseFloat(csvData[index][' "AU - Sweet Potato"'])
        const Tomato = 1 * parseFloat(csvData[index][' "AU - Tomato"'])
        const Turnip = 0.68 * parseFloat(csvData[index][' "AU - Turnip"'])
        const WhiteCabbage = 0.94 * parseFloat(csvData[index][' "AU - Cabbage"'])
        const WhiteOnion = 1 * parseFloat(csvData[index][' "AU - Onion"'])
        const WhitePotato = 1 * parseFloat(csvData[index][' "AU - White Potato"'])
        const YellowPepper = 0.42 * parseFloat(csvData[index][' "AU - Green Pepper"'])
        const Almond = 1 * parseFloat(csvData[index][' "AU - Almond"'])
        const BrazilNut = 1 * parseFloat(csvData[index][' "AU - Brazil Nut"'])
        const CashewNut = 1 * parseFloat(csvData[index][' "AU - Cashew Nut"'])
        const ChestNut = 0.64 * parseFloat(csvData[index][' "AU - Hazelnut"'])
        const ChiaSeeds = 0.47 * parseFloat(csvData[index][' "AU - Flax Seed"'])
        const CocoaBean = 1 * parseFloat(csvData[index][' "AU - Chocolate"'])
        const Coconut = 1 * parseFloat(csvData[index][' "AU - Coconut"'])
        const FlaxSeeds = 1 * parseFloat(csvData[index][' "AU - Flax Seed"'])
        const GreenBean = 1 * parseFloat(csvData[index][' "AU - Green Bean"'])
        const Hazlenut = 1 * parseFloat(csvData[index][' "AU - Hazelnut"'])
        const HaricotBean = 1 * parseFloat(csvData[index][' "AU - Haricot Bean"'])
        const KidneyBean = 1 * parseFloat(csvData[index][' "AU - Coffee"'])
        const MungBean = 0.74 * parseFloat(csvData[index][' "AU - Haricot Bean"'])
        const Peanut = 1 * parseFloat(csvData[index][' "AU - Peanut"'])
        const Pistachio = 1 * parseFloat(csvData[index][' "AU - Pistachio"'])
        const PumpkinSeed = 1.74 * parseFloat(csvData[index][' "AU - Cucumber"'])
        const SesameSeed = 1 * parseFloat(csvData[index][' "AU - Sesame Seed"'])
        const Soybean = 1 * parseFloat(csvData[index][' "AU - Soybean"'])
        const SunflowerSeed = 1 * parseFloat(csvData[index][' "AU - Sunflower Seed"'])
        const VanillaBean = 1 * parseFloat(csvData[index][' "AU - Vanilla Bean"'])
        const Walnut = 1 * parseFloat(csvData[index][' "AU - Walnut"'])
        const CaseinCow = 1 * parseFloat(csvData[index][' "AU - Milk"'])
        const CheddarCheese = 0.95 * parseFloat(csvData[index][' "AU - Milk"'])
        const CottageCheese = 0.76 * parseFloat(csvData[index][' "AU - Milk"'])
        const EggWhite = 1 * parseFloat(csvData[index][' "AU - Egg white"'])
        const EggYolk = 1 * parseFloat(csvData[index][' "AU - Egg Yolk"'])
        const FetaCheese = 0.62 * parseFloat(csvData[index][' "AU - Sheep Milk"'])
        const GoatCheese = 0.6 * parseFloat(csvData[index][' "AU - Goat Milk"'])
        const GoatMilk = 1 * parseFloat(csvData[index][' "AU - Goat Milk"'])
        const MozzarellaCheese = 0.37 * parseFloat(csvData[index][' "AU - Milk"'])
        const SheepMilk = 1 * parseFloat(csvData[index][' "AU - Sheep Milk"'])
        const WheyCow = 1 * parseFloat(csvData[index][' "AU - whey"'])
        const WholeEgg = 1.15 * parseFloat(csvData[index][' "AU - Egg white"'])
        const WholeMilkcow = (parseFloat(csvData[index][' "AU - whey"']) + parseFloat(csvData[index][' "AU - Milk"'])) / 1.84;
        const Peppercorn = 0.21 * parseFloat(csvData[index][' "AU - Green Pepper"'])
        const CayennePepper = 0.44 * parseFloat(csvData[index][' "AU - Green Pepper"'])
        const Cinnamon = 1 * parseFloat(csvData[index][' "AU - Cinnamon"'])
        const Chives = 0.56 * parseFloat(csvData[index][' "AU - Onion"'])
        const CoconutOil = 0.86 * parseFloat(csvData[index][' "AU - Coconut"'])
        const CornOil = 0.65 * parseFloat(csvData[index][' "AU - Corn"'])
        const FlaxseedOil = 0.75 * parseFloat(csvData[index][' "AU - Flax Seed"'])
        const Garlic = 1 * parseFloat(csvData[index][' "AU - Garlic"'])
        const Ginger = 1 * parseFloat(csvData[index][' "AU - Ginger"'])
        const Leek = 0.73 * parseFloat(csvData[index][' "AU - Garlic"'])
        const LemonGrass = 0.74 * parseFloat(csvData[index][' "AU - Wheatgrass"'])
        const Mint = 0.81 * parseFloat(csvData[index][' "AU - Spinach"'])
        const Oregano = 0.39 * parseFloat(csvData[index][' "AU - Spinach"'])
        const OliveOil = 0.68 * parseFloat(csvData[index][' "AU - Olive"'])
        const Paprika = 0.31 * parseFloat(csvData[index][' "AU - Green Pepper"'])
        const Parsley = 0.32 * parseFloat(csvData[index][' "AU - Carrot"'])
        const PeanutOil = 0.48 * parseFloat(csvData[index][' "AU - Peanut"'])
        const Sage = 0.64 * parseFloat(csvData[index][' "AU - Spinach"'])
        const SesameSeedOil = 0.69 * parseFloat(csvData[index][' "AU - Sesame Seed"'])
        const SunflowerOil = 0.89 * parseFloat(csvData[index][' "AU - Sunflower Seed"'])
        const Turmeric = 0.74 * parseFloat(csvData[index][' "AU - Ginger"'])
        const WhitePepper = 0.21 * parseFloat(csvData[index][' "AU - Green Pepper"'])
        const AlmondMilk = 0.88 * parseFloat(csvData[index][' "AU - Almond"'])
        const Blacktea = 1 * parseFloat(csvData[index][' "AU - Black Tea"'])
        const Cocoa = 0.76 * parseFloat(csvData[index][' "AU - Chocolate"'])
        const CashewMilk = 0.79 * parseFloat(csvData[index][' "AU - Cashew Nut"'])
        const CoconutMilk = 0.71 * parseFloat(csvData[index][' "AU - Coconut"'])
        const Coffee = 1 * parseFloat(csvData[index][' "AU - Coffee"'])
        const Greentea = 0.54 * parseFloat(csvData[index][' "AU - Black Tea"'])
        const HazlenutMilk = 0.51 * parseFloat(csvData[index][' "AU - Hazelnut"'])
        const HempMilk = 0.51 * parseFloat(csvData[index][' "AU - Hemp"'])
        const Oatmilk = 0.83 * parseFloat(csvData[index][' "AU - Oat"'])
        const Ricemilk = 0.64 * parseFloat(csvData[index][' "AU - Rice"'])
        const Soyamilk = 1.16 * parseFloat(csvData[index][' "AU - Soybean"'])
        const Whitetea = 0.41 * parseFloat(csvData[index][' "AU - Black Tea"'])
        const Beef = 1 * parseFloat(csvData[index][' "AU - Beef"'])
        const Chicken = 1 * parseFloat(csvData[index][' "AU - Chicken"'])
        const Duck = 1 * parseFloat(csvData[index][' "AU - Duck"'])
        const Goat = 0.36 * parseFloat(csvData[index][' "AU - Lamb"'])
        const Lamb = 1 * parseFloat(csvData[index][' "AU - Lamb"'])
        const Pork = 1 * parseFloat(csvData[index][' "AU - Pork "'])
        const Mutton = 0.75 * parseFloat(csvData[index][' "AU - Lamb"'])
        const Turkey = 1 * parseFloat(csvData[index][' "AU - Turkey"'])
        const Pheasant = 0.17 * parseFloat(csvData[index][' "AU - Duck"'])
        const Veal = 0.86 * parseFloat(csvData[index][' "AU - Beef"'])
        const Venison = 0.87 * parseFloat(csvData[index][' "AU - Beef"'])
        const Anchovy = 0.65 * parseFloat(csvData[index][' "AU - Sardine"'])
        const Cod = 1 * parseFloat(csvData[index][' "AU - Codfish"'])
        const Crab = 1 * parseFloat(csvData[index][' "AU - Crab"'])
        const Crayfish = 0.7 * parseFloat(csvData[index][' "AU - Lobster"'])
        const Haddock = 1.18 * parseFloat(csvData[index][' "AU - Codfish"'])
        const Lobster = 1 * parseFloat(csvData[index][' "AU - Lobster"'])
        const Mackerel = 1 * parseFloat(csvData[index][' "AU - Mackerel"'])
        const Prawn = 0.64 * parseFloat(csvData[index][' "AU - Lobster"'])
        const Salmon = 1 * parseFloat(csvData[index][' "AU - Salmon"'])
        const Sardine = 1 * parseFloat(csvData[index][' "AU - Sardine"'])
        const Shrimp = 1 * parseFloat(csvData[index][' "AU - Shrimp"'])
        const Squid = 0.57 * parseFloat(csvData[index][' "AU - Shrimp"'])
        const TigerPrawn = 0.71 * parseFloat(csvData[index][' "AU - Lobster"'])
        const Trout = 0.31 * parseFloat(csvData[index][' "AU - Salmon"'])
        const Tuna = 1 * parseFloat(csvData[index][' "AU - Tuna"'])
        const BakersYeast = 1.49 * parseFloat(csvData[index][' "AU - Bak / Brew Yeast"'])
        const BarleyFlower = 1.36 * parseFloat(csvData[index][' "AU - Barley Flour"'])
        const BalsamicVinager = 0.43 * parseFloat(csvData[index][' "AU - Grape - white"'])
        const BrownLentils = 0.78 * parseFloat(csvData[index][' "AU - Lentils"'])
        const BrewersYeast = 1 * parseFloat(csvData[index][' "AU - Bak / Brew Yeast"'])
        const Citricacid = 0.64 * parseFloat(csvData[index][' "AU - Lemon"'])
        const Cornflour = 1.97 * parseFloat(csvData[index][' "AU - Corn"'])
        const Hemp = 1 * parseFloat(csvData[index][' "AU - Hemp"'])
        const Hops = 1 * parseFloat(csvData[index][' "AU - Hops"'])
        const Greenlentils = 0.85 * parseFloat(csvData[index][' "AU - Lentils"'])
        const Horseradish = 1.26 * parseFloat(csvData[index][' "AU - Cauliflower"'])
        const Maltvinegar = 0.41 * parseFloat(csvData[index][' "AU - Barley Flour"'])
        const Mustard = 1 * parseFloat(csvData[index][' "AU - Mustard"'])
        const Oatflour = 1.32 * parseFloat(csvData[index][' "AU - Oat"'])
        const Riceflour = 1.37 * parseFloat(csvData[index][' "AU - Rice"'])
        const Sugarcane = 0.76 * parseFloat(csvData[index][' "AU - Wheatgrass"'])
        const VanillaEssence = 1.56 * parseFloat(csvData[index][' "AU - Vanilla Bean"'])
        const Wasabi = 0.86 * parseFloat(csvData[index][' "AU - Mustard"'])
        const Wheatgrass = 1 * parseFloat(csvData[index][' "AU - Wheatgrass"'])
        const Wheatflour = 1.45 * parseFloat(csvData[index][' "AU - Wheat"'])
        const BeefproteinIsolate = 1.36 * parseFloat(csvData[index][' "AU - Beef"'])
        const CaseinproteinIsolate = 0.87 * parseFloat(csvData[index][' "AU - Milk"'])
        const HempproteinIsolate = 0.87 * parseFloat(csvData[index][' "AU - Hemp"'])
        const CollagenBovine = 0.56 * parseFloat(csvData[index][' "AU - Beef"'])
        const MarineCollagen = 0.79 * parseFloat(csvData[index][' "AU - Salmon"'])
        const PeaProteinIsolate = 0.89 * parseFloat(csvData[index][' "AU - Garden Pea"'])
        const RiceProteinIsolate = 0.74 * parseFloat(csvData[index][' "AU - Rice"'])
        const SoyProteinIsolate = 1.19 * parseFloat(csvData[index][' "AU - Soybean"'])
        const Spirulina = 0.21 * parseFloat(csvData[index][' "AU - Spinach"'])
        const WheyProteinIsolate = 0.84 * parseFloat(csvData[index][' "AU - whey"'])


        const desiredVariables = [
          Berley,
          GlutenGliadin,
          Rye,
          Wheat,
          Spelt,
          BrownRice,
          Buckwheat,
          Oat,

          WhiteRice,
          Quinoa,
          Apple,
          Apricot,
          Banana,
          Blackberry,
          Blueberry,
          BlackOlive,
          CantaloupeMelon,
          Cherry,
          Cranberry,
          Date,
          Fig,
          GrapeRed,
          GrapeWhite,
          Grapefruit,
          HoneydewMelon,
          Lemon,
          Lime,
          Lychee,
          Mango,
          Nectarine,
          GreenOlive,
          Orange,
          Peach,
          Pear,
          PersianMelon,
          Pineapple,
          Raisins,
          Pomegranate,
          Raspberry,
          Strawberry,
          Sultana,
          Tangerine,
          Watermelon,
          Asparagus,
          Avocado,
          Beetroot,
          BokChoy,
          Broccoli,
          BrusselSprouts,
          Carrot,
          Cassava,
          Cauliflower,
          Celery,
          ChickPea,
          CowPea,
          Ccumber,
          FeildMushroom,
          GardenPea,
          GreenPepper,
          GreenCabbage,
          IcebergLettuce,
          Kale,
          Maize,
          OysterMushroom,
          Parsnip,
          PortebelloMushroom,
          Pumpkin,
          Radish,
          RedCabbage,
          RedOnion,
          RedPepper,
          Rocket,
          ShiitakeMushroom,
          Spinach,
          SpringOnion,
          SugarSnapPeas,
          SwedishTurnipSwede,
          Sweetcorn,
          SweetPotato,
          Tomato,
          Turnip,
          WhiteCabbage,
          WhiteOnion,
          WhitePotato,
          YellowPepper,
          Almond,
          BrazilNut,
          CashewNut,
          ChestNut,
          ChiaSeeds,
          CocoaBean,
          Coconut,
          FlaxSeeds,
          GreenBean,
          Hazlenut,
          HaricotBean,
          KidneyBean,
          MungBean,
          Peanut,
          Pistachio,
          PumpkinSeed,
          SesameSeed,
          Soybean,
          SunflowerSeed,
          VanillaBean,
          Walnut,
          CaseinCow,
          CheddarCheese,
          CottageCheese,
          EggWhite,
          EggYolk,
          FetaCheese,
          GoatCheese,
          GoatMilk,
          MozzarellaCheese,
          SheepMilk,
          WheyCow,
          WholeEgg,
          WholeMilkcow,
          Peppercorn,
          CayennePepper,
          Cinnamon,
          Chives,
          CoconutOil,
          CornOil,
          FlaxseedOil,
          Garlic,
          Ginger,
          Leek,
          LemonGrass,
          Mint,
          Oregano,
          OliveOil,
          Paprika,
          Parsley,
          PeanutOil,
          Sage,
          SesameSeedOil,
          SunflowerOil,
          Turmeric,
          WhitePepper,
          AlmondMilk,
          Blacktea,
          Cocoa,
          CashewMilk,
          CoconutMilk,
          Coffee,
          Greentea,
          HazlenutMilk,
          Oatmilk,
          HempMilk,
          Ricemilk,
          Soyamilk,
          Whitetea,
          Beef,
          Chicken,
          Duck,
          Goat,
          Lamb,
          Pork,
          Mutton,
          Turkey,
          Pheasant,
          Veal,
          Venison,
          Anchovy,
          Cod,
          Crab,
          Crayfish,
          Haddock,
          Lobster,
          Mackerel,
          Prawn,
          Salmon,
          Sardine,
          Shrimp,
          Squid,
          TigerPrawn,
          Trout,
          Tuna,
          BakersYeast,
          BarleyFlower,
          BalsamicVinager,
          BrownLentils,
          BrewersYeast,
          Citricacid,
          Cornflour,
          Hemp,
          Hops,
          Greenlentils,
          Horseradish,
          Maltvinegar,
          Mustard,
          Oatflour,
          Riceflour,
          Sugarcane,
          VanillaEssence,
          Wasabi,
          Wheatgrass,
          Wheatflour,
          BeefproteinIsolate,
          CaseinproteinIsolate,
          HempproteinIsolate,
          CollagenBovine,
          MarineCollagen,
          PeaProteinIsolate,
          RiceProteinIsolate,
          SoyProteinIsolate,
          Spirulina,
          WheyProteinIsolate,

        ];


        // console.log(desiredVariables);



        return desiredVariables;
      }


      const datacalculation2 = (index) => {


        const Barley = 1 * parseFloat(csvData[index][' "AU - Barley Flour"'])
        const GlutenGliadin = 1 * parseFloat(csvData[index][' "AU - Gluten"'])
        const Wheat = 1 * parseFloat(csvData[index][' "AU - Wheat"'])
        const WhiteRice = 1 * parseFloat(csvData[index][' "AU - Rice"'])
        const Oat = 1 * parseFloat(csvData[index][' "AU - Oat"'])
        const Apple = 1 * parseFloat(csvData[index][' "AU - Apple"'])
        const Apricot = 1 * parseFloat(csvData[index][' "AU - Apricot"'])
        const Banana = 1 * parseFloat(csvData[index][' "AU - Banana"'])
        const Blackberry = 1 * parseFloat(csvData[index][' "AU - Blackberry"'])
        const Blueberry = 1 * parseFloat(csvData[index][' "AU - Blueberry"'])
        const BlackOlive = 1 * parseFloat(csvData[index][' "AU - Olive"'])
        const CantaloupeMelon = 1 * parseFloat(csvData[index][' "AU - Cantaloupe Melon"'])
        const Cherry = 1 * parseFloat(csvData[index][' "AU - Cherry"'])
        const Cranberry = 1 * parseFloat(csvData[index][' "AU - Cranberry"'])
        const GrapeRed = 1 * parseFloat(csvData[index][' "AU - Grape - Red"'])
        const GrapeWhite = 1 * parseFloat(csvData[index][' "AU - Grape - white"'])
        const Grapefruit = 1 * parseFloat(csvData[index][' "AU - Grapefruit"'])
        const HoneydewMelon = 0.88 * parseFloat(csvData[index][' "AU - Cantaloupe Melon"'])
        const Lemon = 1 * parseFloat(csvData[index][' "AU - Lemon"'])
        const Lime = 0.74 * parseFloat(csvData[index][' "AU - Lemon"'])
        const Lychee = 0.21 * parseFloat(csvData[index][' "AU - Mango"'])
        const Mango = 1 * parseFloat(csvData[index][' "AU - Mango"'])
        const Nectarine = 0.89 * parseFloat(csvData[index][' "AU - Orange"'])
        const GreenOlive = 0.72 * parseFloat(csvData[index][' "AU - Olive"'])
        const Orange = 1 * parseFloat(csvData[index][' "AU - Orange"'])
        const Peach = 1 * parseFloat(csvData[index][' "AU - Peach"'])
        const Pear = 1 * parseFloat(csvData[index][' "AU - Pear"'])
        const PersianMelon = 0.31 * parseFloat(csvData[index][' "AU - Cantaloupe Melon"'])
        const Pineapple = 1 * parseFloat(csvData[index][' "AU - Pineapple"'])
        const Raspberry = 1 * parseFloat(csvData[index][' "AU - Raspberry"'])
        const Strawberry = 1 * parseFloat(csvData[index][' "AU - Strawberry"'])
        const Tangerine = 0.9 * parseFloat(csvData[index][' "AU - Orange"'])
        const Watermelon = 1 * parseFloat(csvData[index][' "AU - Watermelon"'])
        const Asparagus = 0.83 * parseFloat(csvData[index][' "AU - Garden Pea"'])
        const Avocado = 1 * parseFloat(csvData[index][' "AU - Avocado"'])
        const Beetroot = 0.71 * parseFloat(csvData[index][' "AU - Spinach"'])
        const Broccoli = 1 * parseFloat(csvData[index][' "AU - Broccoli"'])
        const BrusselSprouts = 0.61 * parseFloat(csvData[index][' "AU - Mustard"'])
        const Carrot = 1 * parseFloat(csvData[index][' "AU - Carrot"'])
        const Cauliflower = 1 * parseFloat(csvData[index][' "AU - Cauliflower"'])
        const Celery = 0.51 * parseFloat(csvData[index][' "AU - Carrot"'])
        const ChickPea = 1 * parseFloat(csvData[index][' "AU - Chick Pea"'])
        const CowPea = 0.61 * parseFloat(csvData[index][' "AU - Garden Pea"'])
        const Cucumber = 1 * parseFloat(csvData[index][' "AU - Cucumber"'])
        const Mushroom = 1 * parseFloat(csvData[index][' "AU - Mushroom"'])
        const GardenPea = 1 * parseFloat(csvData[index][' "AU - Garden Pea"'])
        const Peppers = 1 * parseFloat(csvData[index][' "AU - Green Pepper"'])
        const GreenCabbage = 1 * parseFloat(csvData[index][' "AU - Cabbage"'])
        const IcebergLettuce = 1 * parseFloat(csvData[index][' "AU - Lettuce"'])
        const Kale = 1.27 * parseFloat(csvData[index][' "AU - Sheep Milk"'])
        const Maize = 1.36 * parseFloat(csvData[index][' "AU - Black Tea"'])
        const Pumpkin = 0.67 * parseFloat(csvData[index][' "AU - Mushroom"'])
        const Rocket = 0.68 * parseFloat(csvData[index][' "AU - Sheep Milk"'])
        const Spinach = 1 * parseFloat(csvData[index][' "AU - Spinach"'])
        const SwedishTurnipSwede = 1 * parseFloat(csvData[index][' "AU - Turnip"'])
        const Sweetcorn = 1 * parseFloat(csvData[index][' "AU - Corn"'])
        const SweetPotato = 1 * parseFloat(csvData[index][' "AU - Sweet Potato"'])
        const Tomato = 1 * parseFloat(csvData[index][' "AU - Tomato"'])
        const WhiteOnion = 1 * parseFloat(csvData[index][' "AU - Onion"'])
        const WhitePotato = 1 * parseFloat(csvData[index][' "AU - White Potato"'])
        const Almond = 1 * parseFloat(csvData[index][' "AU - Almond"'])
        const BrazilNut = 1 * parseFloat(csvData[index][' "AU - Brazil Nut"'])
        const CashewNut = 1 * parseFloat(csvData[index][' "AU - Cashew Nut"'])
        const CocoaBean = 1 * parseFloat(csvData[index][' "AU - Chocolate"'])
        const Coconut = 1 * parseFloat(csvData[index][' "AU - Coconut"'])
        const FlaxSeed = 1 * parseFloat(csvData[index][' "AU - Flax Seed"'])
        const GreenBean = 1 * parseFloat(csvData[index][' "AU - Green Bean"'])
        const Hazlenut = 1 * parseFloat(csvData[index][' "AU - Hazelnut"'])
        const HaricotBean = 1 * parseFloat(csvData[index][' "AU - Haricot Bean"'])
        const KidneyBean = 1 * parseFloat(csvData[index][' "AU - Coffee"'])
        const Peanut = 1 * parseFloat(csvData[index][' "AU - Peanut"'])
        const Pistachio = 1 * parseFloat(csvData[index][' "AU - Pistachio"'])
        const SesameSeed = 1 * parseFloat(csvData[index][' "AU - Sesame Seed"'])
        const Soybean = 1 * parseFloat(csvData[index][' "AU - Soybean"'])
        const SunflowerSeed = 1 * parseFloat(csvData[index][' "AU - Sunflower Seed"'])
        const VanillaBean = 1 * parseFloat(csvData[index][' "AU - Vanilla Bean"'])
        const Walnut = 1 * parseFloat(csvData[index][' "AU - Walnut"'])
        const EggWhite = 1 * parseFloat(csvData[index][' "AU - Egg white"'])
        const EggYolk = 1 * parseFloat(csvData[index][' "AU - Egg Yolk"'])
        const GoatMilk = 1 * parseFloat(csvData[index][' "AU - Goat Milk"'])
        const WholeEgg = 1.15 * parseFloat(csvData[index][' "AU - Egg white"'])
        const WholeMilkcow = (parseFloat(csvData[index][' "AU - whey"']) + parseFloat(csvData[index][' "AU - Milk"'])) / 1.84;
        const Cinnamon = 1 * parseFloat(csvData[index][' "AU - Cinnamon"'])
        const Garlic = 1 * parseFloat(csvData[index][' "AU - Garlic"'])
        const Ginger = 1 * parseFloat(csvData[index][' "AU - Ginger"'])
        const Beef = 1 * parseFloat(csvData[index][' "AU - Beef"'])
        const Chicken = 1 * parseFloat(csvData[index][' "AU - Chicken"'])
        const Lamb = 1 * parseFloat(csvData[index][' "AU - Lamb"'])
        const Pork = 1 * parseFloat(csvData[index][' "AU - Pork "'])
        const Turkey = 1 * parseFloat(csvData[index][' "AU - Turkey"'])
        const Cod = 1 * parseFloat(csvData[index][' "AU - Codfish"'])
        const Crab = 1 * parseFloat(csvData[index][' "AU - Crab"'])
        const Lobster = 1 * parseFloat(csvData[index][' "AU - Lobster"'])
        const Mackerel = 1 * parseFloat(csvData[index][' "AU - Mackerel"'])
        const Salmon = 1 * parseFloat(csvData[index][' "AU - Salmon"'])
        const Sardine = 1 * parseFloat(csvData[index][' "AU - Sardine"'])
        const Shrimp = 1 * parseFloat(csvData[index][' "AU - Shrimp"'])
        const Tuna = 1 * parseFloat(csvData[index][' "AU - Tuna"'])
        const BakersYeast = 1.49 * parseFloat(csvData[index][' "AU - Bak / Brew Yeast"'])
        const BrewersYeast = 1 * parseFloat(csvData[index][' "AU - Bak / Brew Yeast"'])



        const desiredVariables = [
          Barley,
          GlutenGliadin,
          Wheat,
          WhiteRice,
          Oat,
          Apple,
          Apricot,
          Banana,
          Blackberry,
          Blueberry,
          BlackOlive,
          CantaloupeMelon,
          Cherry,
          Cranberry,
          GrapeRed,
          GrapeWhite,
          Grapefruit,
          HoneydewMelon,
          Lemon,
          Lime,
          Lychee,
          Mango,
          Nectarine,
          GreenOlive,
          Orange,
          Peach,
          Pear,
          PersianMelon,
          Pineapple,
          Raspberry,
          Strawberry,
          Tangerine,
          Watermelon,
          Asparagus,
          Avocado,
          Beetroot,
          Broccoli,
          BrusselSprouts,
          Carrot,
          Cauliflower,
          Celery,
          ChickPea,
          CowPea,
          Cucumber,
          Mushroom,
          GardenPea,
          Peppers,
          GreenCabbage,
          IcebergLettuce,
          Kale,
          Maize,
          Pumpkin,
          Rocket,
          Spinach,
          SwedishTurnipSwede,
          Sweetcorn,
          SweetPotato,
          Tomato,
          WhiteOnion,
          WhitePotato,
          Almond,
          BrazilNut,
          CashewNut,
          CocoaBean,
          Coconut,
          FlaxSeed,
          GreenBean,
          Hazlenut,
          HaricotBean,
          KidneyBean,
          Peanut,
          Pistachio,
          SesameSeed,
          Soybean,
          SunflowerSeed,
          VanillaBean,
          Walnut,
          EggWhite,
          EggYolk,
          GoatMilk,
          WholeEgg,
          WholeMilkcow,
          Cinnamon,
          Garlic,
          Ginger,
          Beef,
          Chicken,
          Lamb,
          Pork,
          Turkey,
          Cod,
          Crab,
          Lobster,
          Mackerel,
          Salmon,
          Sardine,
          Shrimp,
          Tuna,
          BakersYeast,
          BrewersYeast,
        ];


        // console.log(desiredVariables);



        return desiredVariables;
      }

      csvData.map((item, index) => {
        const result = datacalculation(index)
        const result2 = datacalculation2(index)


        const kitid = Math.round(parseFloat(item[' "Kit ID"']))

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const formattedDate = `${dd}/${mm}/${yyyy}`;

        //  const resultDate =  formattedDate;
        const patientName = item[' "First Name"'] + " " + item[' "Last Name"']
        const SampleDate = item[' "Sample Date"']
        const DOB = item[' "Date of birth"']



        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("kitid", kitid);
        urlencoded.append("result", result);
        urlencoded.append("result2", result2);
        urlencoded.append("DOB", DOB);
        urlencoded.append("resultDate", formattedDate);
        urlencoded.append("patientName", patientName);
        urlencoded.append("SampleDate", SampleDate);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addcalculatedresult", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result === "Kit not found.") { alert("The Kit ID (" + kitid + ") is not found in the database. Please ensure that the Kit is available in the database.") } else { setkit(result) }

          }

          )
          .catch(error => console.log('error', error));




      })


      setpopup(false)



      setTimeout(() => {
        hide(); // Call hide to stop the loading message
        message.success("Action completed successfully");
      }, 2000);
    }

    else { message.error("please upload a file first") }


  }




  const handleClearInput = () => {
    // setFiles([]); // Clear the files state
    fileInputRef.current.value = ''; // Clear the file input value
    setCSVFile(null)
  };






  useEffect(() => {


    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }







  }, [])


  var cout = 0;
  var cout2 = 0;







  var data = [];
  var data222 = [];

  const headers = [
    { label: "S NO", key: "SNO" },
    { label: "DOB", key: "DOB" },
    { label: "patientname", key: "patientname" },
    { label: "Kit ID", key: "Kit ID" },
    { label: "Sample Date", key: "Sample Date" },
    { label: "Result uploaded on", key: "Result Date" },
    { label: "prac name", key: "prac name" },
    { label: "prac email", key: "prac email" },




    { label: "Barley", key: "Barley" },
    { label: "Gluten (Gliadin)", key: "Gluten (Gliadin)" },
    { label: "Rye ", key: "Rye " },
    { label: "Wheat ", key: "Wheat " },
    { label: "Brown Rice", key: "Brown Rice" },
    { label: "Buckwheat ", key: "Buckwheat " },
    { label: "Oat", key: "Oat" },
    { label: "Spelt ", key: "Spelt " },
    { label: "White Rice", key: "White Rice" },
    { label: "Quinoa ", key: "Quinoa " },
    { label: "Apple", key: "Apple" },
    { label: "Apricot", key: "Apricot" },
    { label: "Banana", key: "Banana" },
    { label: "Blackberry", key: "Blackberry" },
    { label: "Blueberry", key: "Blueberry" },
    { label: "Black Olive ", key: "Black Olive " },
    { label: "Cantaloupe Melon", key: "Cantaloupe Melon" },
    { label: "Cherry", key: "Cherry" },
    { label: "Cranberry", key: "Cranberry" },
    { label: "Date", key: "Date" },
    { label: "Fig", key: "Fig" },
    { label: "Grape Red", key: "Grape Red" },
    { label: "Grape White", key: "Grape White" },
    { label: "Grapefruit", key: "Grapefruit" },
    { label: "Honeydew Melon", key: "Honeydew Melon" },
    { label: "Lemon", key: "Lemon" },
    { label: "Lime", key: "Lime" },
    { label: "Lychee", key: "Lychee" },
    { label: "Mango", key: "Mango" },
    { label: "Nectarine ", key: "Nectarine " },
    { label: "Green Olive", key: "Green Olive" },
    { label: "Orange", key: "Orange" },
    { label: "Peach", key: "Peach" },
    { label: "Pear", key: "Pear" },
    { label: "Persian Melon ", key: "Persian Melon " },
    { label: "Pineapple", key: "Pineapple" },
    { label: "Raisins", key: "Raisins" },
    { label: "Pomegranate", key: "Pomegranate" },
    { label: "Raspberry", key: "Raspberry" },
    { label: "Strawberry", key: "Strawberry" },
    { label: "Sultana", key: "Sultana" },
    { label: "Tangerine ", key: "Tangerine " },
    { label: "Watermelon", key: "Watermelon" },
    { label: "Asparagus", key: "Asparagus" },
    { label: "Avocado", key: "Avocado" },
    { label: "Beetroot ", key: "Beetroot " },
    { label: "Bok Choy ", key: "Bok Choy " },
    { label: "Broccoli", key: "Broccoli" },
    { label: "Brussel Sprouts ", key: "Brussel Sprouts " },
    { label: "Carrot", key: "Carrot" },
    { label: "Cassava ", key: "Cassava " },
    { label: "Cauliflower", key: "Cauliflower" },
    { label: "Celery ", key: "Celery " },
    { label: "Chick Pea", key: "Chick Pea" },
    { label: "Cow Pea ", key: "Cow Pea " },
    { label: "Cucumber", key: "Cucumber" },
    { label: "Field Mushroom", key: "Field Mushroom" },
    { label: "Garden Pea", key: "Garden Pea" },
    { label: "Green Pepper ", key: "Green Pepper " },
    { label: "Green Cabbage", key: "Green Cabbage" },
    { label: "Iceberg Lettuce", key: "Iceberg Lettuce" },
    { label: "Kale ", key: "Kale " },
    { label: "Maize ", key: "Maize " },
    { label: "Oyster Mushroom ", key: "Oyster Mushroom " },
    { label: "Parsnip", key: "Parsnip" },
    { label: "Portebello Mushroom ", key: "Portebello Mushroom " },
    { label: "Pumpkin ", key: "Pumpkin " },
    { label: "Radish ", key: "Radish " },
    { label: "Red Cabbage ", key: "Red Cabbage " },
    { label: "Red Onion ", key: "Red Onion " },
    { label: "Red Pepper", key: "Red Pepper" },
    { label: "Rocket ", key: "Rocket " },
    { label: "Shiitake Mushroom", key: "Shiitake Mushroom" },
    { label: "Spinach", key: "Spinach" },
    { label: "Spring Onion", key: "Spring Onion" },
    { label: "Sugar Snap Peas", key: "Sugar Snap Peas" },
    { label: "Swedish Turnip / Swede ", key: "Swedish Turnip / Swede " },
    { label: "Sweetcorn", key: "Sweetcorn" },
    { label: "Sweet Potato", key: "Sweet Potato" },
    { label: "Tomato", key: "Tomato" },
    { label: "Turnip", key: "Turnip" },
    { label: "White Cabbage", key: "White Cabbage" },
    { label: "White Onion", key: "White Onion" },
    { label: "White Potato", key: "White Potato" },
    { label: "Yellow Pepper ", key: "Yellow Pepper " },
    { label: "Almond", key: "Almond" },
    { label: "Brazil Nut", key: "Brazil Nut" },
    { label: "Cashew Nut", key: "Cashew Nut" },
    { label: "Chestnut ", key: "Chestnut " },
    { label: "Chia Seeds ", key: "Chia Seeds " },
    { label: "Cocoa Bean", key: "Cocoa Bean" },
    { label: "Coconut", key: "Coconut" },
    { label: "Flax Seed", key: "Flax Seed" },
    { label: "Green Bean", key: "Green Bean" },
    { label: "Hazlenut", key: "Hazlenut" },
    { label: "Haricot Bean", key: "Haricot Bean" },
    { label: "Kidney Bean", key: "Kidney Bean" },
    { label: "Mung Bean", key: "Mung Bean" },
    { label: "Peanut", key: "Peanut" },
    { label: "Pistachio", key: "Pistachio" },
    { label: "Pumpkin Seed", key: "Pumpkin Seed" },
    { label: "Sesame Seed", key: "Sesame Seed" },
    { label: "Soybean", key: "Soybean" },
    { label: "Sunflower Seed", key: "Sunflower Seed" },
    { label: "Vanilla Bean", key: "Vanilla Bean" },
    { label: "Walnut", key: "Walnut" },
    { label: "Casein (cow)", key: "Casein (cow)" },
    { label: "Cheddar Cheese ", key: "Cheddar Cheese " },
    { label: "Cottage Cheese ", key: "Cottage Cheese " },
    { label: "Egg White", key: "Egg White" },
    { label: "Egg Yolk", key: "Egg Yolk" },
    { label: "Feta Cheese ", key: "Feta Cheese " },
    { label: "Goat Cheese ", key: "Goat Cheese " },
    { label: "Goat Milk", key: "Goat Milk" },
    { label: "Mozzarella Cheese ", key: "Mozzarella Cheese " },
    { label: "Sheep Milk", key: "Sheep Milk" },
    { label: "Whey (cow)", key: "Whey (cow)" },
    { label: "Whole Egg", key: "Whole Egg" },
    { label: "Whole Milk (cow)", key: "Whole Milk (cow)" },
    { label: "Peppercorn", key: "Peppercorn" },
    { label: "Cayenne Pepper ", key: "Cayenne Pepper " },
    { label: "Cinnamon", key: "Cinnamon" },
    { label: "Chives ", key: "Chives " },
    { label: "Coconut Oil", key: "Coconut Oil" },
    { label: "Corn Oil", key: "Corn Oil" },
    { label: "Flaxseed Oil", key: "Flaxseed Oil" },
    { label: "Garlic", key: "Garlic" },
    { label: "Ginger", key: "Ginger" },
    { label: "Leek", key: "Leek" },
    { label: "Lemon Grass", key: "Lemon Grass" },
    { label: "Mint ", key: "Mint " },
    { label: "Oregano ", key: "Oregano " },
    { label: "Olive Oil", key: "Olive Oil" },
    { label: "Paprika", key: "Paprika" },
    { label: "Parsley", key: "Parsley" },
    { label: "Peanut Oil", key: "Peanut Oil" },
    { label: "Sage ", key: "Sage " },
    { label: "Sesame Seed Oil", key: "Sesame Seed Oil" },
    { label: "Sunflower Oil", key: "Sunflower Oil" },
    { label: "Turmeric", key: "Turmeric" },
    { label: "White Pepper ", key: "White Pepper " },
    { label: "Almond Milk", key: "Almond Milk" },
    { label: "Black Tea", key: "Black Tea" },
    { label: "Cacao", key: "Cacao" },
    { label: "Cashew Milk", key: "Cashew Milk" },
    { label: "Coconut Milk", key: "Coconut Milk" },
    { label: "Coffee", key: "Coffee" },
    { label: "Grean Tea", key: "Grean Tea" },
    { label: "Hazlenut Milk", key: "Hazlenut Milk" },
    { label: "Hemp Milk", key: "Hemp Milk" },
    { label: "Oat Milk", key: "Oat Milk" },
    { label: "Rice Milk", key: "Rice Milk" },
    { label: "Soya Milk", key: "Soya Milk" },
    { label: "White Tea", key: "White Tea" },
    { label: "Beef", key: "Beef" },
    { label: "Chicken", key: "Chicken" },
    { label: "Duck", key: "Duck" },
    { label: "Goat ", key: "Goat " },
    { label: "Lamb", key: "Lamb" },
    { label: "Pork", key: "Pork" },
    { label: "Mutton", key: "Mutton" },
    { label: "Turkey", key: "Turkey" },
    { label: "Pheasant", key: "Pheasant" },
    { label: "Veal ", key: "Veal " },
    { label: "Venison ", key: "Venison " },
    { label: "Anchovy", key: "Anchovy" },
    { label: "Cod", key: "Cod" },
    { label: "Crab", key: "Crab" },
    { label: "Crayfish", key: "Crayfish" },
    { label: "Haddock", key: "Haddock" },
    { label: "Lobster", key: "Lobster" },
    { label: "Mackerel", key: "Mackerel" },
    { label: "Prawn", key: "Prawn" },
    { label: "Salmon", key: "Salmon" },
    { label: "Sardine", key: "Sardine" },
    { label: "Shrimp", key: "Shrimp" },
    { label: "Squid", key: "Squid" },
    { label: "Tiger Prawn ", key: "Tiger Prawn " },
    { label: "Trout", key: "Trout" },
    { label: "Tuna", key: "Tuna" },
    { label: "Baker's Yeast", key: "Baker's Yeast" },
    { label: "Barley Flower", key: "Barley Flower" },
    { label: "Balsamic Vinegar", key: "Balsamic Vinegar" },
    { label: "Brown Lentils ", key: "Brown Lentils " },
    { label: "Brewer's Yeast", key: "Brewer's Yeast" },
    { label: "Citric Acid", key: "Citric Acid" },
    { label: "Corn Flour ", key: "Corn Flour " },
    { label: "Hemp", key: "Hemp" },
    { label: "Hops", key: "Hops" },
    { label: "Green Lentils", key: "Green Lentils" },
    { label: "Horseradish ", key: "Horseradish " },
    { label: "Malt Vinegar", key: "Malt Vinegar" },
    { label: "Mustard", key: "Mustard" },
    { label: "Oat Flour ", key: "Oat Flour " },
    { label: "Rice Flour ", key: "Rice Flour " },
    { label: "Sugar Cane ", key: "Sugar Cane " },
    { label: "Vanilla Essence", key: "Vanilla Essence" },
    { label: "Wasabi ", key: "Wasabi " },
    { label: "Wheatgrass", key: "Wheatgrass" },
    { label: "Wheat Flour ", key: "Wheat Flour " },
    { label: "Beef Protein Isolate ", key: "Beef Protein Isolate " },
    { label: "Casein Protein Isolate", key: "Casein Protein Isolate" },
    { label: "Hemp Protein Isolate", key: "Hemp Protein Isolate" },
    { label: "Collagen (Bovine) ", key: "Collagen (Bovine) " },
    { label: "Marine Collagen ", key: "Marine Collagen " },
    { label: "Pea Protein Isolate", key: "Pea Protein Isolate" },
    { label: "Rice Protein Isolate", key: "Rice Protein Isolate" },
    { label: "Soy Protein Isolate", key: "Soy Protein Isolate" },
    { label: "Spirulina ", key: "Spirulina " },
    { label: "Whey Protein Isolate", key: "Whey Protein Isolate" },

    { label: "Total", key: "total" },



  ];


  const headers2 = [
    { label: "S.NO", key: "SNO" },
    { label: "DOB", key: "DOB" },
    { label: "patientname", key: "patientname" },
    { label: "Kit ID", key: "Kit ID" },
    { label: "Sample Date", key: "Sample Date" },
    { label: "Result uploaded on", key: "Result Date" },
    { label: "Practitioner Name", key: "prac name" },
    { label: "Practitioner Email", key: "prac email" },




    { label: "Barley", key: "Barley" },
    { label: "GlutenGliadin", key: "Gluten (Gliadin)" },
    { label: "Wheat ", key: "Wheat " },
    { label: "WhiteRice", key: "White Rice" },
    { label: "Oat", key: "Oat" },
    { label: "Apple", key: "Apple" },
    { label: "Apricot", key: "Apricot" },
    { label: "Banana", key: "Banana" },
    { label: "Blackberry", key: "Blackberry" },
    { label: "Blueberry", key: "Blueberry" },
    { label: "BlackOlive ", key: "Black Olive " },
    { label: "CantaloupeMelon", key: "Cantaloupe Melon" },
    { label: "Cherry", key: "Cherry" },
    { label: "Cranberry", key: "Cranberry" },
    { label: "GrapeRed", key: "Grape Red" },
    { label: "GrapeWhite", key: "Grape White" },
    { label: "Grapefruit", key: "Grapefruit" },
    { label: "HoneydewMelon", key: "Honeydew Melon" },
    { label: "Lemon", key: "Lemon" },
    { label: "Lime", key: "Lime" },
    { label: "Lychee", key: "Lychee" },
    { label: "Mango", key: "Mango" },
    { label: "Nectarine ", key: "Nectarine " },
    { label: "GreenOlive", key: "Green Olive" },
    { label: "Orange", key: "Orange" },
    { label: "Peach", key: "Peach" },
    { label: "Pear", key: "Pear" },
    { label: "PersianMelon ", key: "Persian Melon " },
    { label: "Pineapple", key: "Pineapple" },
    { label: "Raspberry", key: "Raspberry" },
    { label: "Strawberry", key: "Strawberry" },
    { label: "Tangerine ", key: "Tangerine " },
    { label: "Watermelon", key: "Watermelon" },
    { label: "Asparagus", key: "Asparagus" },
    { label: "Avocado", key: "Avocado" },
    { label: "Beetroot ", key: "Beetroot " },
    { label: "Broccoli", key: "Broccoli" },
    { label: "BrusselSprouts ", key: "Brussel Sprouts " },
    { label: "Carrot", key: "Carrot" },
    { label: "Cauliflower", key: "Cauliflower" },
    { label: "Celery ", key: "Celery " },
    { label: "ChickPea", key: "Chick Pea" },
    { label: "CowPea ", key: "Cow Pea " },
    { label: "Cucumber", key: "Cucumber" },
    { label: "Mushroom", key: "Mushroom" },
    { label: "GardenPea", key: "Garden Pea" },
    { label: "Peppers", key: "Peppers" },
    { label: "GreenCabbage", key: "Green Cabbage" },
    { label: "IcebergLettuce", key: "Iceberg Lettuce" },
    { label: "Kale ", key: "Kale " },
    { label: "Maize ", key: "Maize " },
    { label: "Pumpkin ", key: "Pumpkin " },
    { label: "Rocket ", key: "Rocket " },
    { label: "Spinach", key: "Spinach" },
    { label: "SwedishTurnipSwede ", key: "Swedish Turnip / Swede " },
    { label: "Sweetcorn", key: "Sweetcorn" },
    { label: "SweetPotato", key: "Sweet Potato" },
    { label: "Tomato", key: "Tomato" },
    { label: "WhiteOnion", key: "White Onion" },
    { label: "WhitePotato", key: "White Potato" },
    { label: "Almond", key: "Almond" },
    { label: "BrazilNut", key: "Brazil Nut" },
    { label: "CashewNut", key: "Cashew Nut" },
    { label: "CocoaBean", key: "Cocoa Bean" },
    { label: "Coconut", key: "Coconut" },
    { label: "FlaxSeed", key: "Flax Seed" },
    { label: "GreenBean", key: "Green Bean" },
    { label: "Hazlenut", key: "Hazlenut" },
    { label: "HaricotBean", key: "Haricot Bean" },
    { label: "KidneyBean", key: "Kidney Bean" },
    { label: "Peanut", key: "Peanut" },
    { label: "Pistachio", key: "Pistachio" },
    { label: "SesameSeed", key: "Sesame Seed" },
    { label: "Soybean", key: "Soybean" },
    { label: "SunflowerSeed", key: "Sunflower Seed" },
    { label: "Vanilla Bean", key: "Vanilla Bean" },
    { label: "Walnut", key: "Walnut" },
    { label: "EggWhite", key: "Egg White" },
    { label: "EggYolk", key: "Egg Yolk" },
    { label: "GoatMilk", key: "Goat Milk" },
    { label: "WholeEgg", key: "Whole Egg" },
    { label: "WholeMilkcow", key: "Whole Milk (cow)" },
    { label: "Cinnamon", key: "Cinnamon" },
    { label: "Garlic", key: "Garlic" },
    { label: "Ginger", key: "Ginger" },
    { label: "Beef", key: "Beef" },
    { label: "Chicken", key: "Chicken" },
    { label: "Lamb", key: "Lamb" },
    { label: "Pork", key: "Pork" },
    { label: "Turkey", key: "Turkey" },
    { label: "Cod", key: "Cod" },
    { label: "Crab", key: "Crab" },
    { label: "Lobster", key: "Lobster" },
    { label: "Mackerel", key: "Mackerel" },
    { label: "Salmon", key: "Salmon" },
    { label: "Sardine", key: "Sardine" },
    { label: "Shrimp", key: "Shrimp" },
    { label: "Tuna", key: "Tuna" },
    { label: "BakersYeast", key: "Baker's Yeast" },
    { label: "BrewersYeast", key: "Brewer's Yeast" },


    { label: "Total", key: "total" },



  ];



  const downloadCSV = (SNo, DOB, patientname, kitid, SampleDate, resultDate, pracname, pracemail, result) => {
    var data3

    var data2 = [];
    var total = 0;

    result.map((item, index) => {



      var value
      var foodname = "";

      if (index === 0) { foodname = "Barley" }
      if (index === 1) { foodname = "Gluten (Gliadin)" }
      if (index === 2) { foodname = "Rye " }
      if (index === 3) { foodname = "Wheat " }
      if (index === 4) { foodname = "Spelt " }
      if (index === 5) { foodname = "Brown Rice" }
      if (index === 6) { foodname = "Buckwheat " }
      if (index === 7) { foodname = "Oat" }

      if (index === 8) { foodname = "White Rice" }
      if (index === 9) { foodname = "Quinoa " }
      if (index === 10) { foodname = "Apple" }
      if (index === 11) { foodname = "Apricot" }
      if (index === 12) { foodname = "Banana" }
      if (index === 13) { foodname = "Blackberry" }
      if (index === 14) { foodname = "Blueberry" }
      if (index === 15) { foodname = "Black Olive " }
      if (index === 16) { foodname = "Cantaloupe Melon" }
      if (index === 17) { foodname = "Cherry" }
      if (index === 18) { foodname = "Cranberry" }
      if (index === 19) { foodname = "Date" }
      if (index === 20) { foodname = "Fig" }
      if (index === 21) { foodname = "Grape Red" }
      if (index === 22) { foodname = "Grape White" }
      if (index === 23) { foodname = "Grapefruit" }
      if (index === 24) { foodname = "Honeydew Melon" }
      if (index === 25) { foodname = "Lemon" }
      if (index === 26) { foodname = "Lime" }
      if (index === 27) { foodname = "Lychee" }
      if (index === 28) { foodname = "Mango" }
      if (index === 29) { foodname = "Nectarine " }
      if (index === 30) { foodname = "Green Olive" }
      if (index === 31) { foodname = "Orange" }
      if (index === 32) { foodname = "Peach" }
      if (index === 33) { foodname = "Pear" }
      if (index === 34) { foodname = "Persian Melon " }
      if (index === 35) { foodname = "Pineapple" }
      if (index === 36) { foodname = "Raisins" }
      if (index === 37) { foodname = "Pomegranate" }
      if (index === 38) { foodname = "Raspberry" }
      if (index === 39) { foodname = "Strawberry" }
      if (index === 40) { foodname = "Sultana" }
      if (index === 41) { foodname = "Tangerine " }
      if (index === 42) { foodname = "Watermelon" }
      if (index === 43) { foodname = "Asparagus" }
      if (index === 44) { foodname = "Avocado" }
      if (index === 45) { foodname = "Beetroot " }
      if (index === 46) { foodname = "Bok Choy " }
      if (index === 47) { foodname = "Broccoli" }
      if (index === 48) { foodname = "Brussel Sprouts " }
      if (index === 49) { foodname = "Carrot" }
      if (index === 50) { foodname = "Cassava " }
      if (index === 51) { foodname = "Cauliflower" }
      if (index === 52) { foodname = "Celery " }
      if (index === 53) { foodname = "Chick Pea" }
      if (index === 54) { foodname = "Cow Pea " }
      if (index === 55) { foodname = "Cucumber" }
      if (index === 56) { foodname = "Field Mushroom" }
      if (index === 57) { foodname = "Garden Pea" }
      if (index === 58) { foodname = "Green Pepper " }
      if (index === 59) { foodname = "Green Cabbage" }
      if (index === 60) { foodname = "Iceberg Lettuce" }
      if (index === 61) { foodname = "Kale " }
      if (index === 62) { foodname = "Maize " }
      if (index === 63) { foodname = "Oyster Mushroom " }
      if (index === 64) { foodname = "Parsnip" }
      if (index === 65) { foodname = "Portebello Mushroom " }
      if (index === 66) { foodname = "Pumpkin " }
      if (index === 67) { foodname = "Radish " }
      if (index === 68) { foodname = "Red Cabbage " }
      if (index === 69) { foodname = "Red Onion " }
      if (index === 70) { foodname = "Red Pepper" }
      if (index === 71) { foodname = "Rocket " }
      if (index === 72) { foodname = "Shiitake Mushroom" }
      if (index === 73) { foodname = "Spinach" }
      if (index === 74) { foodname = "Spring Onion" }
      if (index === 75) { foodname = "Sugar Snap Peas" }
      if (index === 76) { foodname = "Swedish Turnip / Swede " }
      if (index === 77) { foodname = "Sweetcorn" }
      if (index === 78) { foodname = "Sweet Potato" }
      if (index === 79) { foodname = "Tomato" }
      if (index === 80) { foodname = "Turnip" }
      if (index === 81) { foodname = "White Cabbage" }
      if (index === 82) { foodname = "White Onion" }
      if (index === 83) { foodname = "White Potato" }
      if (index === 84) { foodname = "Yellow Pepper " }
      if (index === 85) { foodname = "Almond" }
      if (index === 86) { foodname = "Brazil Nut" }
      if (index === 87) { foodname = "Cashew Nut" }
      if (index === 88) { foodname = "Chestnut " }
      if (index === 89) { foodname = "Chia Seeds " }
      if (index === 90) { foodname = "Cocoa Bean" }
      if (index === 91) { foodname = "Coconut" }
      if (index === 92) { foodname = "Flax Seed" }
      if (index === 93) { foodname = "Green Bean" }
      if (index === 94) { foodname = "Hazlenut" }
      if (index === 95) { foodname = "Haricot Bean" }
      if (index === 96) { foodname = "Kidney Bean" }
      if (index === 97) { foodname = "Mung Bean" }
      if (index === 98) { foodname = "Peanut" }
      if (index === 99) { foodname = "Pistachio" }
      if (index === 100) { foodname = "Pumpkin Seed" }
      if (index === 101) { foodname = "Sesame Seed" }
      if (index === 102) { foodname = "Soybean" }
      if (index === 103) { foodname = "Sunflower Seed" }
      if (index === 104) { foodname = "Vanilla Bean" }
      if (index === 105) { foodname = "Walnut" }
      if (index === 106) { foodname = "Casein (cow)" }
      if (index === 107) { foodname = "Cheddar Cheese " }
      if (index === 108) { foodname = "Cottage Cheese " }
      if (index === 109) { foodname = "Egg White" }
      if (index === 110) { foodname = "Egg Yolk" }
      if (index === 111) { foodname = "Feta Cheese " }
      if (index === 112) { foodname = "Goat Cheese " }
      if (index === 113) { foodname = "Goat Milk" }
      if (index === 114) { foodname = "Mozzarella Cheese " }
      if (index === 115) { foodname = "Sheep Milk" }
      if (index === 116) { foodname = "Whey (cow)" }
      if (index === 117) { foodname = "Whole Egg" }
      if (index === 118) { foodname = "Whole Milk (cow)" }
      if (index === 119) { foodname = "Peppercorn" }
      if (index === 120) { foodname = "Cayenne Pepper " }
      if (index === 121) { foodname = "Cinnamon" }
      if (index === 122) { foodname = "Chives " }
      if (index === 123) { foodname = "Coconut Oil" }
      if (index === 124) { foodname = "Corn Oil" }
      if (index === 125) { foodname = "Flaxseed Oil" }
      if (index === 126) { foodname = "Garlic" }
      if (index === 127) { foodname = "Ginger" }
      if (index === 128) { foodname = "Leek" }
      if (index === 129) { foodname = "Lemon Grass" }
      if (index === 130) { foodname = "Mint " }
      if (index === 131) { foodname = "Oregano " }
      if (index === 132) { foodname = "Olive Oil" }
      if (index === 133) { foodname = "Paprika" }
      if (index === 134) { foodname = "Parsley" }
      if (index === 135) { foodname = "Peanut Oil" }
      if (index === 136) { foodname = "Sage " }
      if (index === 137) { foodname = "Sesame Seed Oil" }
      if (index === 138) { foodname = "Sunflower Oil" }
      if (index === 139) { foodname = "Turmeric" }
      if (index === 140) { foodname = "White Pepper " }
      if (index === 141) { foodname = "Almond Milk" }
      if (index === 142) { foodname = "Black Tea" }
      if (index === 143) { foodname = "Cacao" }
      if (index === 144) { foodname = "Cashew Milk" }
      if (index === 145) { foodname = "Coconut Milk" }
      if (index === 146) { foodname = "Coffee" }
      if (index === 147) { foodname = "Grean Tea" }
      if (index === 148) { foodname = "Hazlenut Milk" }
      if (index === 149) { foodname = "Hemp Milk" }
      if (index === 150) { foodname = "Oat Milk" }
      if (index === 151) { foodname = "Rice Milk" }
      if (index === 152) { foodname = "Soya Milk" }
      if (index === 153) { foodname = "White Tea" }
      if (index === 154) { foodname = "Beef" }
      if (index === 155) { foodname = "Chicken" }
      if (index === 156) { foodname = "Duck" }
      if (index === 157) { foodname = "Goat " }
      if (index === 158) { foodname = "Lamb" }
      if (index === 159) { foodname = "Pork" }
      if (index === 160) { foodname = "Mutton" }
      if (index === 161) { foodname = "Turkey" }
      if (index === 162) { foodname = "Pheasant" }
      if (index === 163) { foodname = "Veal " }
      if (index === 164) { foodname = "Venison " }
      if (index === 165) { foodname = "Anchovy" }
      if (index === 166) { foodname = "Cod" }
      if (index === 167) { foodname = "Crab" }
      if (index === 168) { foodname = "Crayfish" }
      if (index === 169) { foodname = "Haddock" }
      if (index === 170) { foodname = "Lobster" }
      if (index === 171) { foodname = "Mackerel" }
      if (index === 172) { foodname = "Prawn" }
      if (index === 173) { foodname = "Salmon" }
      if (index === 174) { foodname = "Sardine" }
      if (index === 175) { foodname = "Shrimp" }
      if (index === 176) { foodname = "Squid" }
      if (index === 177) { foodname = "Tiger Prawn " }
      if (index === 178) { foodname = "Trout" }
      if (index === 179) { foodname = "Tuna" }
      if (index === 180) { foodname = "Baker's Yeast" }
      if (index === 181) { foodname = "Barley Flower" }
      if (index === 182) { foodname = "Balsamic Vinegar" }
      if (index === 183) { foodname = "Brown Lentils " }
      if (index === 184) { foodname = "Brewer's Yeast" }
      if (index === 185) { foodname = "Citric Acid" }
      if (index === 186) { foodname = "Corn Flour " }
      if (index === 187) { foodname = "Hemp" }
      if (index === 188) { foodname = "Hops" }
      if (index === 189) { foodname = "Green Lentils" }
      if (index === 190) { foodname = "Horseradish " }
      if (index === 191) { foodname = "Malt Vinegar" }
      if (index === 192) { foodname = "Mustard" }
      if (index === 193) { foodname = "Oat Flour " }
      if (index === 194) { foodname = "Rice Flour " }
      if (index === 195) { foodname = "Sugar Cane " }
      if (index === 196) { foodname = "Vanilla Essence" }
      if (index === 197) { foodname = "Wasabi " }
      if (index === 198) { foodname = "Wheatgrass" }
      if (index === 199) { foodname = "Wheat Flour " }
      if (index === 200) { foodname = "Beef Protein Isolate " }
      if (index === 201) { foodname = "Casein Protein Isolate" }
      if (index === 202) { foodname = "Hemp Protein Isolate" }
      if (index === 203) { foodname = "Collagen (Bovine) " }
      if (index === 204) { foodname = "Marine Collagen " }
      if (index === 205) { foodname = "Pea Protein Isolate" }
      if (index === 206) { foodname = "Rice Protein Isolate" }
      if (index === 207) { foodname = "Soy Protein Isolate" }
      if (index === 208) { foodname = "Spirulina " }
      if (index === 209) { foodname = "Whey Protein Isolate" }



      if (item < 0) { value = 0; } else { value = Math.round(item) }

      total = total + value
      data2.push({ foodname: foodname, value: value })




    })


    data3 = [{ SNO: SNo, "DOB": DOB, "patientname": patientname, "Kit ID": kitid, "Sample Date": SampleDate, "Result Date": resultDate, "prac name": pracname, "prac email": pracemail, total: total }];




    const resultsMap = new Map(data2.map(result => [result.foodname, result.value]));

    // Merge the data based on the name
    const newDataWithFoods = data3.map(item => {
      const mergedItem = { ...item };
      data2.forEach(result => {
        mergedItem[result.foodname] = resultsMap.get(result.foodname);
      });
      return mergedItem;
    });

    data.push(newDataWithFoods[0]);


  }



  const downloadCSV2 = (SNo, DOB, patientname, kitid, SampleDate, resultDate, pracname, pracemail, result) => {
    var data3

    var data2 = [];
    var total = 0;

    result.map((item, index) => {



      var value
      var foodname = "";

      if (index === 0) { foodname = "Barley" }
      if (index === 1) { foodname = "Gluten (Gliadin)" }
      if (index === 2) { foodname = "Wheat " }
      if (index === 3) { foodname = "White Rice" }
      if (index === 4) { foodname = "Oat" }
      if (index === 5) { foodname = "Apple" }
      if (index === 6) { foodname = "Apricot" }
      if (index === 7) { foodname = "Banana" }
      if (index === 8) { foodname = "Blackberry" }
      if (index === 9) { foodname = "Blueberry" }
      if (index === 10) { foodname = "Black Olive " }
      if (index === 11) { foodname = "Cantaloupe Melon" }
      if (index === 12) { foodname = "Cherry" }
      if (index === 13) { foodname = "Cranberry" }
      if (index === 14) { foodname = "Grape Red" }
      if (index === 15) { foodname = "Grape White" }
      if (index === 16) { foodname = "Grapefruit" }
      if (index === 17) { foodname = "Honeydew Melon" }
      if (index === 18) { foodname = "Lemon" }
      if (index === 19) { foodname = "Lime" }
      if (index === 20) { foodname = "Lychee" }
      if (index === 21) { foodname = "Mango" }
      if (index === 22) { foodname = "Nectarine " }
      if (index === 23) { foodname = "Green Olive" }
      if (index === 24) { foodname = "Orange" }
      if (index === 25) { foodname = "Peach" }
      if (index === 26) { foodname = "Pear" }
      if (index === 27) { foodname = "Persian Melon " }
      if (index === 28) { foodname = "Pineapple" }
      if (index === 29) { foodname = "Raspberry" }
      if (index === 30) { foodname = "Strawberry" }
      if (index === 31) { foodname = "Tangerine " }
      if (index === 32) { foodname = "Watermelon" }
      if (index === 33) { foodname = "Asparagus" }
      if (index === 34) { foodname = "Avocado" }
      if (index === 35) { foodname = "Beetroot " }
      if (index === 36) { foodname = "Broccoli" }
      if (index === 37) { foodname = "Brussel Sprouts " }
      if (index === 38) { foodname = "Carrot" }
      if (index === 39) { foodname = "Cauliflower" }
      if (index === 40) { foodname = "Celery " }
      if (index === 41) { foodname = "Chick Pea" }
      if (index === 42) { foodname = "Cow Pea " }
      if (index === 43) { foodname = "Cucumber" }
      if (index === 44) { foodname = "Mushroom" }
      if (index === 45) { foodname = "Garden Pea" }
      if (index === 46) { foodname = "Peppers" }
      if (index === 47) { foodname = "Green Cabbage" }
      if (index === 48) { foodname = "Iceberg Lettuce" }
      if (index === 49) { foodname = "Kale " }
      if (index === 50) { foodname = "Maize " }
      if (index === 51) { foodname = "Pumpkin " }
      if (index === 52) { foodname = "Rocket " }
      if (index === 53) { foodname = "Spinach" }
      if (index === 54) { foodname = "Swedish Turnip / Swede " }
      if (index === 55) { foodname = "Sweetcorn" }
      if (index === 56) { foodname = "Sweet Potato" }
      if (index === 57) { foodname = "Tomato" }
      if (index === 58) { foodname = "White Onion" }
      if (index === 59) { foodname = "White Potato" }
      if (index === 60) { foodname = "Almond" }
      if (index === 61) { foodname = "Brazil Nut" }
      if (index === 62) { foodname = "Cashew Nut" }
      if (index === 63) { foodname = "Cocoa Bean" }
      if (index === 64) { foodname = "Coconut" }
      if (index === 65) { foodname = "Flax Seed" }
      if (index === 66) { foodname = "Green Bean" }
      if (index === 67) { foodname = "Hazlenut" }
      if (index === 68) { foodname = "Haricot Bean" }
      if (index === 69) { foodname = "Kidney Bean" }
      if (index === 70) { foodname = "Peanut" }
      if (index === 71) { foodname = "Pistachio" }
      if (index === 72) { foodname = "Sesame Seed" }
      if (index === 73) { foodname = "Soybean" }
      if (index === 74) { foodname = "Sunflower Seed" }
      if (index === 75) { foodname = "Vanilla Bean" }
      if (index === 76) { foodname = "Walnut" }
      if (index === 77) { foodname = "Egg White" }
      if (index === 78) { foodname = "Egg Yolk" }
      if (index === 79) { foodname = "Goat Milk" }
      if (index === 80) { foodname = "Whole Egg" }
      if (index === 81) { foodname = "Whole Milk (cow)" }
      if (index === 82) { foodname = "Cinnamon" }
      if (index === 83) { foodname = "Garlic" }
      if (index === 84) { foodname = "Ginger" }
      if (index === 85) { foodname = "Beef" }
      if (index === 86) { foodname = "Chicken" }
      if (index === 87) { foodname = "Lamb" }
      if (index === 88) { foodname = "Pork" }
      if (index === 89) { foodname = "Turkey" }
      if (index === 90) { foodname = "Cod" }
      if (index === 91) { foodname = "Crab" }
      if (index === 92) { foodname = "Lobster" }
      if (index === 93) { foodname = "Mackerel" }
      if (index === 94) { foodname = "Salmon" }
      if (index === 95) { foodname = "Sardine" }
      if (index === 96) { foodname = "Shrimp" }
      if (index === 97) { foodname = "Tuna" }
      if (index === 98) { foodname = "Baker's Yeast" }
      if (index === 99) { foodname = "Brewer's Yeast" }



      if (item < 0) { value = 0; } else { value = Math.round(item) }

      total = total + value
      data2.push({ foodname: foodname, value: value })




    })


    data3 = [{ SNO: SNo, "DOB": DOB, "patientname": patientname, "Kit ID": kitid, "Sample Date": SampleDate, "Result Date": resultDate, "prac name": pracname, "prac email": pracemail, total: total }];




    const resultsMap = new Map(data2.map(result => [result.foodname, result.value]));

    // Merge the data based on the name
    const newDataWithFoods = data3.map(item => {
      const mergedItem = { ...item };
      data2.forEach(result => {
        mergedItem[result.foodname] = resultsMap.get(result.foodname);
      });
      return mergedItem;
    });

    data222.push(newDataWithFoods[0]);



  }







  const sumofresult = () => {

    var sum = 0;
    Result2.map((value, index) => {
      if (value <= 0) { sum = sum + 0 }
      else { sum = sum + Math.round(value) }
    })
    // console.log(sum
    return sum;
  }

  const sum2 = () => {

    var sum = 0;
    Result2.map((value, index) => {
      if (value <= 0) { sum = sum + 0 }
      else { sum = sum + Math.round(value) }
    })


    var sum2 = ''

    const xMin = 100;
    const xMax = 400;
    const valueMin = -40;
    const valueMax = 40;
    var refValue = 0

    if (sum <= 100) { refValue = -45 } else if (sum >= 400) { refValue = 45 } else { refValue = (sum - xMin) / (xMax - xMin) * (valueMax - valueMin) + valueMin; }




    sum2 = (refValue.toString() + "%")
    return sum2;
  }


  var valuemorethen10 = []
  var value5to5 = []


  const sorting = () => {


    value5to5.sort(function (a, b) {
      return b.value - a.value; // Compare in descending order
    });



    valuemorethen10.sort(function (a, b) {
      return b.value - a.value; // Compare in descending order
    });


  }




  var valuemorethen102 = []
  var value5to52 = []


  const sorting2 = () => {


    value5to5.sort(function (a, b) {
      return b.value - a.value; // Compare in descending order
    });



    valuemorethen10.sort(function (a, b) {
      return b.value - a.value; // Compare in descending order
    });


  }




  const DownloadPDF = async (name) => {












    const contant2 = document.getElementById('ali')


    const opt = {
      margin: 0,
      filename: 'my_webpage.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const seee = html2pdf().from(contant2).set(opt).outputPdf();

    seee.save("YourGutMap IgG FS 210 - " + name + ".pdf")






    // return (

    // )
  }




  const DownloadPDF2 = async (name) => {



    const contant2 = document.getElementById('ali2')


    const opt = {
      margin: 0,
      filename: 'my_webpage.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const seee = html2pdf().from(contant2).set(opt).outputPdf();

    seee.save("YourGutMap IgG FS 100 - " + name + ".pdf")






    // return (

    // )
  }




  const [searchTerm, setSearchTerm] = useState('');
  var options = [];

  const dooption = () => {



    practitioner.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      options.push({ value: value.name })
      options.push({ value: value.email })


    })

    kit.map((value, index) => {

      if (value.result && value.result.length !== 0) {
        options.push({ value: value.kitid })
        options.push({ value: value.patientName })
        options.push({ value: value.resultDate })
        options.push({ value: value.SampleDate })
        options.push({ value: value.DOB })

      }

    })






  }



  const handleInputChange = async (e) => {
    e.preventDefault()

    const intttt = (searchTerm)

    const filteredSuggestionspra = practitioner.filter((item) =>

      item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



    );




    const intttt2 = parseInt(searchTerm)

    const filteredSuggestions = kit.filter((item) => {
      const resullll = item.result;
      if (resullll && resullll.length !== 0) {
        return (
          item.DOB.toLowerCase().includes(intttt.toLowerCase())
          || item.patientName.toLowerCase().includes(intttt.toLowerCase())
          || item.SampleDate.toLowerCase().includes(intttt.toLowerCase())
          || item.resultDate.toLowerCase().includes(intttt.toLowerCase())
          || item.kitid === intttt2
          || filteredSuggestionspra.map((itemmm) => itemmm._id).includes(item.assignedto.toLowerCase())




        );
      }


    });


    // const intttt = parseInt(searchTerm)

    // // Filter the Kit array based on the user's input
    // const filteredSuggestions = kit.filter((item) =>
    //   item.kitid === intttt
    // );


    // console.log(filteredSuggestions)
    setkit(filteredSuggestions)
    setsearchdone(true)

  };
  const [searchdone, setsearchdone] = useState(false)

  const clearsearch = async () => {
    const hide = message.loading("Action in progress", 0)

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }



    await setsearchdone(false)
    setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  }


  const [userinfo, setuserinfo] = useState([])
  const [popupdetails, setpopupdetails] = useState(false)









  const items = [
    {
      key: '1',
      label: (
        <a href={"/dashboard/view-report/" + userinfo._id} target="_blank" rel="noopener noreferrer" onClick={async () => {






        }}>
          View result
        </a>
      ),
      icon: <MdOutlineViewDay style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {

          const hide = message.loading("Action in progress", 0)

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", userinfo._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };





          try {
            const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions);
            const result = await response.json();
            setkit2(result);
            setResult2(result.result);

            // Wait for 2 seconds
            await new Promise(resolve => setTimeout(resolve, 500));

            // Call the DownloadPDF function after waiting
            DownloadPDF(userinfo.patientName);
          } catch (error) {
            console.log('error', error);
          }



          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);

        }}>
          Download Results
        </a>
      ),
      icon: <BsCloudDownload style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {
          const hide = message.loading("Action in progress", 0)

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", userinfo._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };






          const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresults210", requestOptions);
          const result = await response.json();
          await setkit(result);

          await setpopupdetails(false)






          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);
        }}>
          Send Results
        </a>
      ),
      disabled: userinfo.ackresult,

      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

  ];

  const items2 = [
    {
      key: '1',
      label: (
        <a href={"/dashboard/view-report100/" + userinfo._id} target="_blank" rel="noopener noreferrer" onClick={async () => {






        }}>
          View result
        </a>
      ),
      icon: <MdOutlineViewDay style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {


          const hide = message.loading("Action in progress", 0)
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", userinfo._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };





          try {
            const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions);
            const result = await response.json();
            setkit2(result);
            setResult222(result.result2);

            // Wait for 2 seconds
            await new Promise(resolve => setTimeout(resolve, 500));

            // Call the DownloadPDF function after waiting
            DownloadPDF2(userinfo.patientName);
          } catch (error) {
            console.log('error', error);
          }




          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);
        }}>
          Download Results
        </a>
      ),
      icon: <BsCloudDownload style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {

          const hide = message.loading("Action in progress", 0)
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", userinfo._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };






          const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresults", requestOptions);
          const result = await response.json();
          await setkit(result);

          await setpopupdetails(false)




          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);


        }}>
          Send Results
        </a>
      ),
      disabled: userinfo.ackresult,

      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

  ];



  var sno1 = 1
  var sno2 = 1



  return (<>
    {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && <>
      <Navbar />
      <div className='deshboardmain'>
        <Sidemanu />
        <div className='adminkitmainbody'>
          <div className='header'>
            <h1>Food Sensitivity Reports</h1>
            {dooption()}
            {searchdone === false && <>
              <form onSubmit={handleInputChange}>
                <AutoComplete
                  type="number"
                  style={{ width: 200 }}
                  options={options}
                  placeholder="Email/Name/Patient Name/Sample Date/upload/practitioner/NamePractitioner Email "
                  filterOption={(inputValue, options) =>
                    options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    //  console.log(kitss) 
                  }
                  onChange={(inputValue) => setSearchTerm(inputValue)}
                />
                <button>Search</button>
              </form>
            </>}
            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
            </>}
          </div>

          {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
            <div className='addbutton'><button onClick={() => setpopup(true)}><ImUpload2 style={{ width: '20px', height: '20px' }} />Upload CSV</button></div>



            {popup === true && <>
              <div onClick={() => {
                setpopup(false)
                setCSVFile(null)
              }
              } className='popupbg'></div>
              <div className='popup'>



                <div className="file-drop-zone"


                  onDrop={handleFileDrop}
                  onDragOver={(e) => e.preventDefault()}

                >

                  <label className="custom-file-upload" >

                    <div className='clickablediv'>


                      <ImUpload2 style={{ width: '100px', height: '100px' }} />
                      <h4>Click or drag file to this area to upload</h4>


                    </div>
                    <input
                      multiple="off"
                      ref={fileInputRef}
                      className='inputbuttontouploadfile'
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                    />

                  </label>

                </div>

                <div className='filename'> {csvFile && (<> <p>Selected CSV File: {csvFile.name}</p><AiFillDelete onClick={handleClearInput} className='hovar' style={{ width: '30px', height: '30px', color: 'red' }} /></>)}</div>





                <div className='bownpopupbutton'>
                  <button onClick={() => {
                    setpopup(false)
                    setCSVFile(null)
                  }
                  } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                  <button onClick={datauploaded} style={{ backgroundColor: '#4180b7' }}>Upload</button>




                </div>

              </div>
            </>}
          </>}
          {popupdetails === true && <>
            <div onClick={() => {
              setpopupdetails(false)
              setCSVFile(null)
            }
            } className='popupbg'></div>
            <div className='popup'>



              <div className='header' >
                <h2>Kits Result Details</h2>
              </div>
              <div className='kitdetailsmain' >




                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <GiFirstAidKit style={{ width: '20px', height: '20px', margin: "0 10px" }} /> KIT ID</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.kitid} </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    {practitioner.map((item2, index) => (<>

                      {item2._id === userinfo.assignedto && <>

                        <td>{item2.name}</td>

                        {/* <td>{item2.email}</td> */}
                      </>}</>))}


                  </h3>
                </div>
                <div className='kitdetails'>

                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbUserShare style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    {practitioner.map((item2, index) => (<>

                      {item2._id === userinfo.assignedto && <>
                        {downloadCSV2(cout, userinfo.DOB, userinfo.patientName, userinfo.kitid, userinfo.SampleDate, userinfo.resultDate, item2.name, item2.email, userinfo.result2)}

                        {/* <td>{item2.name}</td> */}

                        <td>{item2.email}</td>
                      </>}</>))}



                  </h3>
                </div>

                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <ImUserTie style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Patient Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.patientName} </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <BsCalendar2DateFill style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Date of birth</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.DOB} </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlinePendingActions style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Sample Date</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.SampleDate} </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlineUpdate style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Result upload on</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.resultDate} </h3>
                </div>
                <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <AiOutlineInteraction style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                  <Dropdown menu={{ items: userinfo.Kittype === "210" ? items : items2 }} placement="bottomRight" arrow>
                    <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                  </Dropdown>


                </h3></div>





              </div>




              <div className='bownpopupbutton'>
                <button onClick={() => {
                  setpopupdetails(false)

                }
                } style={{ border: '1px solid red', color: 'black' }} >cancel</button>












              </div>

            </div>
          </>}











          {kit.length !== 0 && <>

            <div className='header'><h3>Food Sensitivity 210</h3></div>
            <div className='imp'>
              <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Kit ID</th>

                    <th>Sample Date</th>

                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>      <th>Practitioner Name</th></>}
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>       <th>Practitioner Email</th></>}
                    {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>     <th>Patient Name</th></>}

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>


                  {kit.map((item, index) =>

                    <>
                      {(item.result.length !== 0 && item.Kittype === "210") && <>










                        <tr>
                          <td>{sno1++}</td>

                          <td>{item.kitid}</td>


                          <td>{item.SampleDate}</td>



                          {practitioner.map((item2, index) => (<>

                            {item2._id === item.assignedto && <>
                              {downloadCSV(cout, item.DOB, item.patientName, item.kitid, item.SampleDate, item.resultDate, item2.name, item2.email, item.result)}
                              <td>{item2.name}</td>

                              <td>{item2.email}</td>
                            </>}</>))}







                          {/* <td className='assignbuttom' ><BsCloudDownload onClick={async () => {


                          var myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                          var urlencoded = new URLSearchParams();
                          urlencoded.append("_id", item._id);

                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                          };





                          try {
                            const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions);
                            const result = await response.json();
                            setkit2(result);
                            setResult2(result.result);

                            // Wait for 2 seconds
                            await new Promise(resolve => setTimeout(resolve, 500));

                            // Call the DownloadPDF function after waiting
                            DownloadPDF(item.patientName);
                          } catch (error) {
                            console.log('error', error);
                          }











                        }} className='hovar' style={{ width: '30px', height: '30px', color: '#4180b7' }} /></td> */}


                          {/* {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                          {item.ackresult ? (<><td>Result Already Sent</td></>) : (<>
                            <td  ><AiOutlineMail onClick={async () => {



                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", item._id);

                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };






                              const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresults210", requestOptions);
                              const result = await response.json();
                              await setkit(result);












                            }} className='hovar' style={{ width: '30px', height: '30px', color: '#4180b7' }} /></td>

                          </>)}
                        </>} */}
                          {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>
                            <td  >
                              {item.patientName}
                            </td>
                          </>}
                          <td  >
                            <button className='button' onClick={() => {
                              setpopupdetails(true)
                              setuserinfo(item)
                            }}>Detail</button>
                          </td>
                        </tr>




                      </>}






                    </>
                  )}



                </tbody>
              </table>

            </div>

            {/* <a onClick={downloadCSV} style={{ display: 'flex', textAlign: 'right', justifyContent: 'end', width: '100%' }} href='/'>Download this data as CSV</a > */}

            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
              <CSVLink data={data} headers={headers} filename={"yourgutmap-food-sensitivity Report.csv"}>
                <button className='downloadbutton' > <FaDownload style={{ width: '20px', height: '20px' }} /> Download this data as CSV</button >
              </CSVLink>

            </div>
          </>}



          {kit.length !== 0 && <>

            <div className='header'><h3>Food Sensitivity 100</h3></div>
            <div className='imp'>
              <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Kit ID</th>

                    <th>Sample Date</th>

                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>      <th>Practitioner Name</th></>}
                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>       <th>Practitioner Email</th></>}
                    {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>     <th>Patient Name</th></>}

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>


                  {kit.map((item, index) =>

                    <>
                      {(item.result.length !== 0 && item.Kittype === "100") && <>










                        <tr>
                          <td>{sno2++}</td>

                          <td>{item.kitid}</td>


                          <td>{item.SampleDate}</td>



                          {practitioner.map((item2, index) => (<>

                            {item2._id === item.assignedto && <>
                              {downloadCSV(cout, item.DOB, item.patientName, item.kitid, item.SampleDate, item.resultDate, item2.name, item2.email, item.result)}
                              <td>{item2.name}</td>

                              <td>{item2.email}</td>
                            </>}</>))}







                          {/* <td className='assignbuttom' ><BsCloudDownload onClick={async () => {


                          var myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                          var urlencoded = new URLSearchParams();
                          urlencoded.append("_id", item._id);

                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                          };





                          try {
                            const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions);
                            const result = await response.json();
                            setkit2(result);
                            setResult2(result.result);

                            // Wait for 2 seconds
                            await new Promise(resolve => setTimeout(resolve, 500));

                            // Call the DownloadPDF function after waiting
                            DownloadPDF(item.patientName);
                          } catch (error) {
                            console.log('error', error);
                          }











                        }} className='hovar' style={{ width: '30px', height: '30px', color: '#4180b7' }} /></td> */}


                          {/* {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                          {item.ackresult ? (<><td>Result Already Sent</td></>) : (<>
                            <td  ><AiOutlineMail onClick={async () => {



                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", item._id);

                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };






                              const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresults210", requestOptions);
                              const result = await response.json();
                              await setkit(result);












                            }} className='hovar' style={{ width: '30px', height: '30px', color: '#4180b7' }} /></td>

                          </>)}
                        </>} */}
                          {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>
                            <td  >
                              {item.patientName}
                            </td>
                          </>}
                          <td  >
                            <button className='button' onClick={() => {
                              setpopupdetails(true)
                              setuserinfo(item)
                            }}>Detail</button>
                          </td>
                        </tr>




                      </>}






                    </>
                  )}



                </tbody>
              </table>

            </div>


            {/* <a onClick={downloadCSV} style={{ display: 'flex', textAlign: 'right', justifyContent: 'end', width: '100%' }} href='/'>Download this data as CSV</a > */}

            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
              <CSVLink data={data222} headers={headers2} filename={"yourgutmap-food-sensitivity Screen 100 Report.csv"}>
                <button className='downloadbutton' > <FaDownload style={{ width: '20px', height: '20px' }} /> Download this data as CSV</button >
              </CSVLink>

            </div>
          </>}

          {kit.length === 0 && <>

            <img alt='' src='/empty.gif' width={"40%"} />
          </>}



        </div>


      </div>










      <div style={{ display: 'none' }}>
        <div id='ali' >

          {/* page 1 */}
          <div className='pdfpage' >
            <img alt='' src='/g1.png' className='uperimg' />
            <div className='pageecontant'>

              <img alt='' src='/logo.png' className='logoimg' />

            </div>
            <img alt='' src='/g2.png' width={"100%"} className='uperimg' />
          </div>

          {/* page 2 */}
          <div className='pdfpage' >
            <img alt='' src='/g1.png' className='uperimg' />
            <div className='pageecontant'>

              <img alt='' src='/logo.png' className='logoimg2' />

              <div className='info'>
                <div style={{ display: 'flex', gap: "10px" }}>  <h2 style={{ color: '#6E4E9F' }}> Patient Name : </h2> <h2 style={{ color: '#6799C6' }}> {" "} {kit2.patientName}</h2> </div>
                <div style={{ display: 'flex', gap: "10px" }}>    <h2 style={{ color: '#6E4E9F' }}>Sample ID : </h2> <h2 style={{ color: '#6799C6' }}>{kit2.kitid}</h2> </div>
                <div style={{ display: 'flex', gap: "10px" }}>   <h2 style={{ color: '#6E4E9F' }}>Sample Date : </h2> <h2 style={{ color: '#6799C6' }}>{kit2.SampleDate}</h2> </div>
              </div>

              <div className='info' style={{ backgroundColor: '#6E4E9F' }} >
                <h3 style={{ color: 'white' }}>Total IgG Immune Load </h3>
                <p style={{ color: 'white' }} >Your total IgG reactivity shows the current immune load your body is experiencing with your current
                  diet. Research shows by following your IgG guided dietary changes, total immune load can be
                  reduced.</p>

                <div className='page2leveldiv' >

                  <div className='page2leveldivinner'>

                    <div className='raisedimmuneload' style={{ color: '#6E4E9F' }}> <div className='triangle'></div>
                      raised<br />
                      immune<br />
                      load<br />
                    </div>
                    <div className='immuneload' style={{ color: '#6E4E9F', left: sum2() }}>
                      Your immune load
                      <div className='plus-sign ' >


                      </div>
                      <div className='plus-signbefore'><h4>{sumofresult()}</h4></div>

                      <div className='triangle2'></div>

                    </div>
                    <div className='highimmuneload' style={{ color: '#6E4E9F' }}> <div className='triangle'></div>
                      high<br />
                      immune<br />
                      load<br />
                    </div>

                  </div>

                </div>
              </div>


              <div className='info' style={{ color: '#6E4E9F' }}>
                <p>Your food specific IgG antibody diet guide shows IgG reactions and cross reactions to foods and drinks.
                  <br />   <br />  This information can be used by a qualified healthcare practitioner to adjust your diet. Any changes in diet including the
                  removal of foods should be overseen by a qualified professional, to prevent nutrient deficiencies.
                  <br />   <br />  Please note: a food specific IgG antibody test does not analyse classical allergies, where IgE antibodies are involved. Food
                  specific IgG antibody testing cannot diagnose conditions such as Coeliac Disease, or Lactose Intolerance. Any pre existing
                  allergies or negative reactions to foods should be discussed with your health practitioner.
                  YourGutMap defines Food Sensitivity as a food specific IgG antibody reaction. This is not a diagnostic test</p>
              </div>

            </div>
            <img alt='' src='/g2.png' className='uperimg' />
          </div>


          {/* page 3 */}
          <div className='pdfpage222'>
            <img alt='' src='/g1.png' className='uperimg' style={{ height: "180px" }} />
            <div className='pageecontant2'>

              <div className='info' >
                <h1 style={{ color: '#6799C6' }}>{kit2.patientName}</h1>
                <h3 style={{ color: '#6E4E9F' }}>YourGutMap IgG Food Sensitivity Screen</h3>


                <div className="indicatorp">

                  <div style={{ backgroundColor: '#d2393f' }}>Elevated {'>'} 10 IgG AU/ml</div>
                  <div style={{ backgroundColor: '#ecca99' }}>Borderline 5 - 9 IgG AU/ml</div>
                  <div style={{ backgroundColor: '#4280b8' }}>Normal {'<'} 5 IgG AU/ml</div>

                </div>


                <div className="calcuresultp">


                  {Result2.map((item, index) => {

                    var value
                    var foodname = "";

                    if (index === 0) { foodname = "Barley" }
                    if (index === 1) { foodname = "Gluten (Gliadin)" }
                    if (index === 2) { foodname = "Rye " }
                    if (index === 3) { foodname = "Wheat " }
                    if (index === 4) { foodname = "Spelt " }
                    if (index === 5) { foodname = "Brown Rice" }
                    if (index === 6) { foodname = "Buckwheat " }
                    if (index === 7) { foodname = "Oat" }

                    if (index === 8) { foodname = "White Rice" }
                    if (index === 9) { foodname = "Quinoa " }
                    if (index === 10) { foodname = "Apple" }
                    if (index === 11) { foodname = "Apricot" }
                    if (index === 12) { foodname = "Banana" }
                    if (index === 13) { foodname = "Blackberry" }
                    if (index === 14) { foodname = "Blueberry" }
                    if (index === 15) { foodname = "Black Olive " }
                    if (index === 16) { foodname = "Cantaloupe Melon" }
                    if (index === 17) { foodname = "Cherry" }
                    if (index === 18) { foodname = "Cranberry" }
                    if (index === 19) { foodname = "Date" }
                    if (index === 20) { foodname = "Fig" }
                    if (index === 21) { foodname = "Grape Red" }
                    if (index === 22) { foodname = "Grape White" }
                    if (index === 23) { foodname = "Grapefruit" }
                    if (index === 24) { foodname = "Honeydew Melon" }
                    if (index === 25) { foodname = "Lemon" }
                    if (index === 26) { foodname = "Lime" }
                    if (index === 27) { foodname = "Lychee" }
                    if (index === 28) { foodname = "Mango" }
                    if (index === 29) { foodname = "Nectarine " }
                    if (index === 30) { foodname = "Green Olive" }
                    if (index === 31) { foodname = "Orange" }
                    if (index === 32) { foodname = "Peach" }
                    if (index === 33) { foodname = "Pear" }
                    if (index === 34) { foodname = "Persian Melon " }
                    if (index === 35) { foodname = "Pineapple" }
                    if (index === 36) { foodname = "Raisins" }
                    if (index === 37) { foodname = "Pomegranate" }
                    if (index === 38) { foodname = "Raspberry" }
                    if (index === 39) { foodname = "Strawberry" }
                    if (index === 40) { foodname = "Sultana" }
                    if (index === 41) { foodname = "Tangerine " }
                    if (index === 42) { foodname = "Watermelon" }
                    if (index === 43) { foodname = "Asparagus" }
                    if (index === 44) { foodname = "Avocado" }
                    if (index === 45) { foodname = "Beetroot " }
                    if (index === 46) { foodname = "Bok Choy " }
                    if (index === 47) { foodname = "Broccoli" }
                    if (index === 48) { foodname = "Brussel Sprouts " }
                    if (index === 49) { foodname = "Carrot" }
                    if (index === 50) { foodname = "Cassava " }
                    if (index === 51) { foodname = "Cauliflower" }
                    if (index === 52) { foodname = "Celery " }
                    if (index === 53) { foodname = "Chick Pea" }
                    if (index === 54) { foodname = "Cow Pea " }
                    if (index === 55) { foodname = "Cucumber" }
                    if (index === 56) { foodname = "Field Mushroom" }
                    if (index === 57) { foodname = "Garden Pea" }
                    if (index === 58) { foodname = "Green Pepper " }
                    if (index === 59) { foodname = "Green Cabbage" }
                    if (index === 60) { foodname = "Iceberg Lettuce" }
                    if (index === 61) { foodname = "Kale " }
                    if (index === 62) { foodname = "Maize " }
                    if (index === 63) { foodname = "Oyster Mushroom " }
                    if (index === 64) { foodname = "Parsnip" }
                    if (index === 65) { foodname = "Portebello Mushroom " }
                    if (index === 66) { foodname = "Pumpkin " }
                    if (index === 67) { foodname = "Radish " }
                    if (index === 68) { foodname = "Red Cabbage " }
                    if (index === 69) { foodname = "Red Onion " }
                    if (index === 70) { foodname = "Red Pepper" }
                    if (index === 71) { foodname = "Rocket " }
                    if (index === 72) { foodname = "Shiitake Mushroom" }
                    if (index === 73) { foodname = "Spinach" }
                    if (index === 74) { foodname = "Spring Onion" }
                    if (index === 75) { foodname = "Sugar Snap Peas" }
                    if (index === 76) { foodname = "Swedish Turnip / Swede " }
                    if (index === 77) { foodname = "Sweetcorn" }
                    if (index === 78) { foodname = "Sweet Potato" }
                    if (index === 79) { foodname = "Tomato" }
                    if (index === 80) { foodname = "Turnip" }
                    if (index === 81) { foodname = "White Cabbage" }
                    if (index === 82) { foodname = "White Onion" }
                    if (index === 83) { foodname = "White Potato" }
                    if (index === 84) { foodname = "Yellow Pepper " }
                    if (index === 85) { foodname = "Almond" }
                    if (index === 86) { foodname = "Brazil Nut" }
                    if (index === 87) { foodname = "Cashew Nut" }
                    if (index === 88) { foodname = "Chestnut " }
                    if (index === 89) { foodname = "Chia Seeds " }
                    if (index === 90) { foodname = "Cocoa Bean" }
                    if (index === 91) { foodname = "Coconut" }
                    if (index === 92) { foodname = "Flax Seed" }
                    if (index === 93) { foodname = "Green Bean" }
                    if (index === 94) { foodname = "Hazlenut" }
                    if (index === 95) { foodname = "Haricot Bean" }
                    if (index === 96) { foodname = "Kidney Bean" }
                    if (index === 97) { foodname = "Mung Bean" }
                    if (index === 98) { foodname = "Peanut" }
                    if (index === 99) { foodname = "Pistachio" }
                    if (index === 100) { foodname = "Pumpkin Seed" }
                    if (index === 101) { foodname = "Sesame Seed" }
                    if (index === 102) { foodname = "Soybean" }
                    if (index === 103) { foodname = "Sunflower Seed" }
                    if (index === 104) { foodname = "Vanilla Bean" }
                    if (index === 105) { foodname = "Walnut" }
                    if (index === 106) { foodname = "Casein (cow)" }
                    if (index === 107) { foodname = "Cheddar Cheese " }
                    if (index === 108) { foodname = "Cottage Cheese " }
                    if (index === 109) { foodname = "Egg White" }
                    if (index === 110) { foodname = "Egg Yolk" }
                    if (index === 111) { foodname = "Feta Cheese " }
                    if (index === 112) { foodname = "Goat Cheese " }
                    if (index === 113) { foodname = "Goat Milk" }
                    if (index === 114) { foodname = "Mozzarella Cheese " }
                    if (index === 115) { foodname = "Sheep Milk" }
                    if (index === 116) { foodname = "Whey (cow)" }
                    if (index === 117) { foodname = "Whole Egg" }
                    if (index === 118) { foodname = "Whole Milk (cow)" }
                    if (index === 119) { foodname = "Peppercorn" }
                    if (index === 120) { foodname = "Cayenne Pepper " }
                    if (index === 121) { foodname = "Cinnamon" }
                    if (index === 122) { foodname = "Chives " }
                    if (index === 123) { foodname = "Coconut Oil" }
                    if (index === 124) { foodname = "Corn Oil" }
                    if (index === 125) { foodname = "Flaxseed Oil" }
                    if (index === 126) { foodname = "Garlic" }
                    if (index === 127) { foodname = "Ginger" }
                    if (index === 128) { foodname = "Leek" }
                    if (index === 129) { foodname = "Lemon Grass" }
                    if (index === 130) { foodname = "Mint " }
                    if (index === 131) { foodname = "Oregano " }
                    if (index === 132) { foodname = "Olive Oil" }
                    if (index === 133) { foodname = "Paprika" }
                    if (index === 134) { foodname = "Parsley" }
                    if (index === 135) { foodname = "Peanut Oil" }
                    if (index === 136) { foodname = "Sage " }
                    if (index === 137) { foodname = "Sesame Seed Oil" }
                    if (index === 138) { foodname = "Sunflower Oil" }
                    if (index === 139) { foodname = "Turmeric" }
                    if (index === 140) { foodname = "White Pepper " }
                    if (index === 141) { foodname = "Almond Milk" }
                    if (index === 142) { foodname = "Black Tea" }
                    if (index === 143) { foodname = "Cacao" }
                    if (index === 144) { foodname = "Cashew Milk" }
                    if (index === 145) { foodname = "Coconut Milk" }
                    if (index === 146) { foodname = "Coffee" }
                    if (index === 147) { foodname = "Grean Tea" }
                    if (index === 148) { foodname = "Hazlenut Milk" }
                    if (index === 149) { foodname = "Hemp Milk" }
                    if (index === 150) { foodname = "Oat Milk" }
                    if (index === 151) { foodname = "Rice Milk" }
                    if (index === 152) { foodname = "Soya Milk" }
                    if (index === 153) { foodname = "White Tea" }
                    if (index === 154) { foodname = "Beef" }
                    if (index === 155) { foodname = "Chicken" }
                    if (index === 156) { foodname = "Duck" }
                    if (index === 157) { foodname = "Goat " }
                    if (index === 158) { foodname = "Lamb" }
                    if (index === 159) { foodname = "Pork" }
                    if (index === 160) { foodname = "Mutton" }
                    if (index === 161) { foodname = "Turkey" }
                    if (index === 162) { foodname = "Pheasant" }
                    if (index === 163) { foodname = "Veal " }
                    if (index === 164) { foodname = "Venison " }
                    if (index === 165) { foodname = "Anchovy" }
                    if (index === 166) { foodname = "Cod" }
                    if (index === 167) { foodname = "Crab" }
                    if (index === 168) { foodname = "Crayfish" }
                    if (index === 169) { foodname = "Haddock" }
                    if (index === 170) { foodname = "Lobster" }
                    if (index === 171) { foodname = "Mackerel" }
                    if (index === 172) { foodname = "Prawn" }
                    if (index === 173) { foodname = "Salmon" }
                    if (index === 174) { foodname = "Sardine" }
                    if (index === 175) { foodname = "Shrimp" }
                    if (index === 176) { foodname = "Squid" }
                    if (index === 177) { foodname = "Tiger Prawn " }
                    if (index === 178) { foodname = "Trout" }
                    if (index === 179) { foodname = "Tuna" }
                    if (index === 180) { foodname = "Baker's Yeast" }
                    if (index === 181) { foodname = "Barley Flower" }
                    if (index === 182) { foodname = "Balsamic Vinegar" }
                    if (index === 183) { foodname = "Brown Lentils " }
                    if (index === 184) { foodname = "Brewer's Yeast" }
                    if (index === 185) { foodname = "Citric Acid" }
                    if (index === 186) { foodname = "Corn Flour " }
                    if (index === 187) { foodname = "Hemp" }
                    if (index === 188) { foodname = "Hops" }
                    if (index === 189) { foodname = "Green Lentils" }
                    if (index === 190) { foodname = "Horseradish " }
                    if (index === 191) { foodname = "Malt Vinegar" }
                    if (index === 192) { foodname = "Mustard" }
                    if (index === 193) { foodname = "Oat Flour " }
                    if (index === 194) { foodname = "Rice Flour " }
                    if (index === 195) { foodname = "Sugar Cane " }
                    if (index === 196) { foodname = "Vanilla Essence" }
                    if (index === 197) { foodname = "Wasabi " }
                    if (index === 198) { foodname = "Wheatgrass" }
                    if (index === 199) { foodname = "Wheat Flour " }
                    if (index === 200) { foodname = "Beef Protein Isolate " }
                    if (index === 201) { foodname = "Casein Protein Isolate" }
                    if (index === 202) { foodname = "Hemp Protein Isolate" }
                    if (index === 203) { foodname = "Collagen (Bovine) " }
                    if (index === 204) { foodname = "Marine Collagen " }
                    if (index === 205) { foodname = "Pea Protein Isolate" }
                    if (index === 206) { foodname = "Rice Protein Isolate" }
                    if (index === 207) { foodname = "Soy Protein Isolate" }
                    if (index === 208) { foodname = "Spirulina " }
                    if (index === 209) { foodname = "Whey Protein Isolate" }



                    if (item < 0) { value = 0; } else { value = Math.round(item) }

                    if (value >= 10) {
                      valuemorethen10.push({ value: value, foodname: foodname });
                    } else if (value >= 5 && value <= 9) {
                      value5to5.push({ value: value, foodname: foodname });
                    }
                    return (<>

                      {index === 0 && <>  <h3 style={{ width: '100%', marginTop: "20px" }}>Grains & Staples containing Gluten</h3>   </>}

                      {index === 5 && <>  <h3 style={{ width: '100%', marginTop: "15px" }}>Grains & Staples non Gluten</h3>   </>}

                      {index === 10 && <>  <h3 style={{ width: '100%', marginTop: "15px" }}>Fruits</h3>   </>}

                      {index === 43 && <>  <h3 style={{ width: '100%', marginTop: "26px" }}>Vegetables</h3>   </>}

                      {index === 85 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Nuts, Seeds & Beans</h3>   </>}

                      {index === 106 && <>  <h3 style={{ width: '100%', marginTop: "4px" }}>Dairy & Eggs</h3>   </>}

                      {index === 119 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}
                      {index === 119 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}


                      {index === 119 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Herbs, Spices & Oils</h3>   </>}

                      {index === 141 && <>  <h3 style={{ width: '100%', marginTop: "4px" }}>Drinks</h3>   </>}


                      {index === 154 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}
                      {index === 154 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}


                      {index === 154 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Meat & Fish</h3>   </>}


                      {index === 180 && <>  <div style={{ marginBottom: '270px' }}></div> </>}


                      {index === 180 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}
                      {index === 180 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}



                      {index === 180 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Others</h3>   </>}

                      {index === 200 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Supplements</h3>   </>}




                      {index === 22 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}
                      {index === 59 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}
                      {/* {index === 84 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>} */}

                      <div className="boxxp">
                        {value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{value}</div></>}
                        {(value >= 5 && value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{value}</div></>}
                        {value < 5 && <>  <div className='number' style={{ backgroundColor: '#4280b8' }}>{value}</div></>}
                        <div className='name'><h4>{foodname}</h4></div>
                      </div>


                      {index === 21 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}


                      {index === 209 && <>  <div style={{ marginBottom: '0px' }}></div> </>}
                      {index === 209 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}


                      {index === 58 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}

                      {index === 84 && <>  <div style={{ marginBottom: '300px' }}></div> </>}

                      {index === 84 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}

                      {index === 84 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}


                      {/* {index === 209 && <>  <div style={{marginBottom:'365px'}}></div> </>} */}

                    </>)
                  })}





                </div>


              </div>

            </div>



            {/* <img alt='' src='/g2.png' className='uperimg' /> */}
          </div>









          {/* page 4 */}



          {value5to5.length + valuemorethen10.length <= 32 && <>




            <div className='pdfpage4' >

              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                  {sorting()}

                  <div className="calcuresultp">
                    <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                    {valuemorethen10.map((item2, index2) => {


                      return <>

                        <div className="boxxp">
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}



                    <p style={{ width: '100%', marginTop: '60px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                    {value5to5.map((item2, index2) => {


                      return <>

                        <div className="boxxp">
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}







                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>



          </>}



          {/* page 4 */}

          {value5to5.length + valuemorethen10.length > 32 && <>
            <div className='pdfpage4'  >

              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                  {sorting()}

                  <div className="calcuresultp">
                    <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                    {valuemorethen10.map((item2, index2) => {


                      return <>

                        <div className="boxxp">
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}











                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>




          </>}




          {/* page 4 */}
          {value5to5.length + valuemorethen10.length > 32 && <>
            <div className='pdfpage4' >

              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  {sorting()}

                  <div className="calcuresultp">



                    <p style={{ width: '100%', marginTop: '60px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                    {value5to5.map((item2, index2) => {


                      return <>

                        <div className="boxxp">
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}







                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>

          </>}










        </div>


        <div id='ali2' >

          {/* page 1 */}
          <div className='pdfpage' >
            <img alt='' src='/g1.png' className='uperimg' />
            <div className='pageecontant'>

              <img alt='' src='/logo.png' className='logoimg' />

            </div>
            <img alt='' src='/g2.png' width={"100%"} className='uperimg' />
          </div>

          {/* page 2 */}
          <div className='pdfpage' >
            <img alt='' src='/g1.png' className='uperimg' />
            <div className='pageecontant'>

              <img alt='' src='/logo.png' className='logoimg2' />

              <div className='info'>
                <div style={{ display: 'flex', gap: "10px" }}>  <h2 style={{ color: '#6E4E9F' }}> Patient Name : </h2> <h2 style={{ color: '#6799C6' }}> {" "} {kit2.patientName}</h2> </div>
                <div style={{ display: 'flex', gap: "10px" }}>    <h2 style={{ color: '#6E4E9F' }}>Sample ID : </h2> <h2 style={{ color: '#6799C6' }}>{kit2.kitid}</h2> </div>
                <div style={{ display: 'flex', gap: "10px" }}>   <h2 style={{ color: '#6E4E9F' }}>Sample Date : </h2> <h2 style={{ color: '#6799C6' }}>{kit2.SampleDate}</h2> </div>
              </div>

              <div className='info' style={{ backgroundColor: '#6E4E9F' }} >
                <h3 style={{ color: 'white' }}>Total IgG Immune Load </h3>
                <p style={{ color: 'white' }} >Your total IgG reactivity shows the current immune load your body is experiencing with your current
                  diet. Research shows by following your IgG guided dietary changes, total immune load can be
                  reduced.</p>

                <div className='page2leveldiv' >



                  <h1 style={{ fontSize: '300%', color: '#6799C6' }}>Food Sensitivity Screen 100</h1>



                </div>
              </div>


              <div className='info' style={{ color: '#6E4E9F' }}>
                <p>Your food specific IgG antibody diet guide shows IgG reactions and cross reactions to foods and drinks.
                  <br />   <br />  This information can be used by a qualified healthcare practitioner to adjust your diet. Any changes in diet including the
                  removal of foods should be overseen by a qualified professional, to prevent nutrient deficiencies.
                  <br />   <br />  Please note: a food specific IgG antibody test does not analyse classical allergies, where IgE antibodies are involved. Food
                  specific IgG antibody testing cannot diagnose conditions such as Coeliac Disease, or Lactose Intolerance. Any pre existing
                  allergies or negative reactions to foods should be discussed with your health practitioner.
                  YourGutMap defines Food Sensitivity as a food specific IgG antibody reaction. This is not a diagnostic test</p>
              </div>

            </div>
            <img alt='' src='/g2.png' className='uperimg' />
          </div>


          {/* page 3 */}
          <div className='pdfpage222'>
            <img alt='' src='/g1.png' className='uperimg' style={{ height: "180px" }} />
            <div className='pageecontant2'>

              <div className='info' >
                <h1 style={{ color: '#6799C6' }}>{kit2.patientName}</h1>
                <h3 style={{ color: '#6E4E9F' }}>YourGutMap IgG Food Sensitivity Screen</h3>


                <div className="indicatorp">

                  <div style={{ backgroundColor: '#d2393f' }}>Elevated {'>'} 10 IgG AU/ml</div>
                  <div style={{ backgroundColor: '#ecca99' }}>Borderline 5 - 9 IgG AU/ml</div>
                  <div style={{ backgroundColor: '#4280b8' }}>Normal {'<'} 5 IgG AU/ml</div>

                </div>


                <div className="calcuresultp">


                  {Result222.map((item, index) => {

                    var value
                    var foodname = "";

                    if (index === 0) { foodname = "Barley" }
                    if (index === 1) { foodname = "Gluten (Gliadin)" }
                    if (index === 2) { foodname = "Wheat " }
                    if (index === 3) { foodname = "White Rice" }
                    if (index === 4) { foodname = "Oat" }
                    if (index === 5) { foodname = "Apple" }
                    if (index === 6) { foodname = "Apricot" }
                    if (index === 7) { foodname = "Banana" }
                    if (index === 8) { foodname = "Blackberry" }
                    if (index === 9) { foodname = "Blueberry" }
                    if (index === 10) { foodname = "Black Olive " }
                    if (index === 11) { foodname = "Cantaloupe Melon" }
                    if (index === 12) { foodname = "Cherry" }
                    if (index === 13) { foodname = "Cranberry" }
                    if (index === 14) { foodname = "Grape Red" }
                    if (index === 15) { foodname = "Grape White" }
                    if (index === 16) { foodname = "Grapefruit" }
                    if (index === 17) { foodname = "Honeydew Melon" }
                    if (index === 18) { foodname = "Lemon" }
                    if (index === 19) { foodname = "Lime" }
                    if (index === 20) { foodname = "Lychee" }
                    if (index === 21) { foodname = "Mango" }
                    if (index === 22) { foodname = "Nectarine " }
                    if (index === 23) { foodname = "Green Olive" }
                    if (index === 24) { foodname = "Orange" }
                    if (index === 25) { foodname = "Peach" }
                    if (index === 26) { foodname = "Pear" }
                    if (index === 27) { foodname = "Persian Melon " }
                    if (index === 28) { foodname = "Pineapple" }
                    if (index === 29) { foodname = "Raspberry" }
                    if (index === 30) { foodname = "Strawberry" }
                    if (index === 31) { foodname = "Tangerine " }
                    if (index === 32) { foodname = "Watermelon" }
                    if (index === 33) { foodname = "Asparagus" }
                    if (index === 34) { foodname = "Avocado" }
                    if (index === 35) { foodname = "Beetroot " }
                    if (index === 36) { foodname = "Broccoli" }
                    if (index === 37) { foodname = "Brussel Sprouts " }
                    if (index === 38) { foodname = "Carrot" }
                    if (index === 39) { foodname = "Cauliflower" }
                    if (index === 40) { foodname = "Celery " }
                    if (index === 41) { foodname = "Chick Pea" }
                    if (index === 42) { foodname = "Cow Pea " }
                    if (index === 43) { foodname = "Cucumber" }
                    if (index === 44) { foodname = "Mushroom" }
                    if (index === 45) { foodname = "Garden Pea" }
                    if (index === 46) { foodname = "Peppers" }
                    if (index === 47) { foodname = "Green Cabbage" }
                    if (index === 48) { foodname = "Iceberg Lettuce" }
                    if (index === 49) { foodname = "Kale " }
                    if (index === 50) { foodname = "Maize " }
                    if (index === 51) { foodname = "Pumpkin " }
                    if (index === 52) { foodname = "Rocket " }
                    if (index === 53) { foodname = "Spinach" }
                    if (index === 54) { foodname = "Swedish Turnip / Swede " }
                    if (index === 55) { foodname = "Sweetcorn" }
                    if (index === 56) { foodname = "Sweet Potato" }
                    if (index === 57) { foodname = "Tomato" }
                    if (index === 58) { foodname = "White Onion" }
                    if (index === 59) { foodname = "White Potato" }
                    if (index === 60) { foodname = "Almond" }
                    if (index === 61) { foodname = "Brazil Nut" }
                    if (index === 62) { foodname = "Cashew Nut" }
                    if (index === 63) { foodname = "Cocoa Bean" }
                    if (index === 64) { foodname = "Coconut" }
                    if (index === 65) { foodname = "Flax Seed" }
                    if (index === 66) { foodname = "Green Bean" }
                    if (index === 67) { foodname = "Hazlenut" }
                    if (index === 68) { foodname = "Haricot Bean" }
                    if (index === 69) { foodname = "Kidney Bean" }
                    if (index === 70) { foodname = "Peanut" }
                    if (index === 71) { foodname = "Pistachio" }
                    if (index === 72) { foodname = "Sesame Seed" }
                    if (index === 73) { foodname = "Soybean" }
                    if (index === 74) { foodname = "Sunflower Seed" }
                    if (index === 75) { foodname = "Vanilla Bean" }
                    if (index === 76) { foodname = "Walnut" }
                    if (index === 77) { foodname = "Egg White" }
                    if (index === 78) { foodname = "Egg Yolk" }
                    if (index === 79) { foodname = "Goat Milk" }
                    if (index === 80) { foodname = "Whole Egg" }
                    if (index === 81) { foodname = "Whole Milk (cow)" }
                    if (index === 82) { foodname = "Cinnamon" }
                    if (index === 83) { foodname = "Garlic" }
                    if (index === 84) { foodname = "Ginger" }
                    if (index === 85) { foodname = "Beef" }
                    if (index === 86) { foodname = "Chicken" }
                    if (index === 87) { foodname = "Lamb" }
                    if (index === 88) { foodname = "Pork" }
                    if (index === 89) { foodname = "Turkey" }
                    if (index === 90) { foodname = "Cod" }
                    if (index === 91) { foodname = "Crab" }
                    if (index === 92) { foodname = "Lobster" }
                    if (index === 93) { foodname = "Mackerel" }
                    if (index === 94) { foodname = "Salmon" }
                    if (index === 95) { foodname = "Sardine" }
                    if (index === 96) { foodname = "Shrimp" }
                    if (index === 97) { foodname = "Tuna" }
                    if (index === 98) { foodname = "Baker's Yeast" }
                    if (index === 99) { foodname = "Brewer's Yeast" }




                    if (item < 0) { value = 0; } else { value = Math.round(item) }

                    if (value >= 10) {
                      valuemorethen102.push({ value: value, foodname: foodname });
                    } else if (value >= 5 && value <= 9) {
                      value5to52.push({ value: value, foodname: foodname });
                    }
                    return (<>



                      {index === 0 && <>  <h3 style={{ width: '100%' }}>Grains & Staples containing Gluten</h3>   </>}

                      {index === 3 && <>  <h3 style={{ width: '100%', marginTop: "35px" }}>Grains & Staples non Gluten</h3>   </>}

                      {index === 5 && <>  <h3 style={{ width: '100%', marginTop: "35px" }}>Fruits</h3>   </>}

                      {index === 33 && <>  <h3 style={{ width: '100%', marginTop: "26px" }}>Vegetables</h3>   </>}

                      {index === 60 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Nuts, Seeds & Beans</h3>   </>}

                      {index === 77 && <>  <h3 style={{ width: '100%', marginTop: "4px" }}>Dairy & Eggs</h3>   </>}



                      {index === 82 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Herbs, Spices & Oils</h3>   </>}





                      {index === 85 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Meat & Fish</h3>   </>}


                      {index === 180 && <>  <div style={{ marginBottom: '270px' }}></div> </>}




                      {index === 98 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Others</h3>   </>}






                      {index === 21 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}
                      {index === 59 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}














                      <div className="boxxp">
                        {value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{value}</div></>}
                        {(value >= 5 && value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{value}</div></>}
                        {value < 5 && <>  <div className='number' style={{ backgroundColor: '#4280b8' }}>{value}</div></>}
                        <div className='name'><h4>{foodname}</h4></div>
                      </div>

                      {index === 20 && <>  <div style={{ marginBottom: '8px' }}></div> </>}
                      {index === 20 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}




                      {index === 58 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}

                      {index === 84 && <>  <div style={{ marginBottom: '65px' }}></div> </>}

                      {index === 84 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}

                      {index === 84 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}


                      {index === 99 && <>  <div style={{ marginBottom: '320px' }}></div> </>}

                      {index === 99 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}


                      {/* {index === 209 && <>  <div style={{marginBottom:'365px'}}></div> </>} */}

                    </>)
                  })}





                </div>


              </div>

            </div>



            {/* <img alt='' src='/g2.png' className='uperimg' /> */}
          </div>











          {/* page 4 */} {value5to5.length + valuemorethen10.length <= 32 && <>
            <div className='pdfpage4'  >
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                  {sorting2()}

                  <div className="calcuresultp">
                    <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                    {valuemorethen102.map((item2, index2) => {


                      return <>

                        <div className="boxxp">
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}






                    <p style={{ width: '100%', marginTop: '50px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                    {value5to52.map((item2, index2) => {


                      return <>

                        <div className="boxxp">
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}







                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>
          </>}

          {/* page 4 */}  {value5to5.length + valuemorethen10.length > 32 && <>
            <div className='pdfpage4'  >
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                  {sorting2()}

                  <div className="calcuresultp">
                    <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                    {valuemorethen102.map((item2, index2) => {


                      return <>

                        <div className="boxxp">
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}




                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>
          </>}

          {/* page 4 */}  {value5to5.length + valuemorethen10.length > 32 && <>
            <div className='pdfpage4'  >
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  {sorting2()}

                  <div className="calcuresultp">



                    <p style={{ width: '100%', marginTop: '50px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                    {value5to52.map((item2, index2) => {


                      return <>

                        <div className="boxxp">
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}







                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>

          </>}



        </div>

      </div>






    </>} </>)
}









export default Adminreport