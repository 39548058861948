import React, { useState } from 'react'
import Cookies from 'js-cookie';
import { BiHelpCircle, BiSolidAddToQueue } from 'react-icons/bi';
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/helpcenter.css"
import { useEffect } from 'react';
import { message } from 'antd';


function Helpcenter() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")

    const [question, setquestion] = useState([])
    const [popup, setpopup] = useState(false)

    const [inportquestion, setinportquestion] = useState("")
    const [inputanser, setinputanser] = useState("")

    useEffect(() => {
        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



            var requestOptions = {
                method: 'GET',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallfaq", requestOptions)
                .then(response => response.json())
                .then(result => setquestion(result))
        }

    }, [])


    const addquestion = async (e) => {
        e.preventDefault()
        const hide = message.loading("Action in progress", 0)

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("question", inportquestion);
        urlencoded.append("answer", inputanser);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addfaq", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setquestion(result)
                setpopup(false)
                setinportquestion("")
                setinputanser("")

            })
            .catch((error) => console.error(error));

        await setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
        }, 2000);

    }

    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <Navbar />
                <div className='deshboardmain'>
                    <Sidemanu />
                    <div className='adminkitmainbody'>
                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><BiHelpCircle style={{ width: '50px', height: '50px' }} />Help Center</h1>
                        </div>



                        {popup === true && <>
                            <div onClick={() => {
                                setpopup(false)
                                setinportquestion("")
                                setinputanser("")
                            }
                            } className='popupbg'></div>
                            <div className='popup'>
                                <div className='header' >
                                    <h2>Enter Question </h2>
                                </div>
                                <form onSubmit={addquestion} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ width: '100%', marginTop: "20px", display: 'flex', flexDirection: 'column', alignItems: 'baseline', textAlign: 'left' }}>
                                        <leble>Question :</leble>
                                        <input style={{ width: '95%' }} value={inportquestion} onChange={(e) => setinportquestion(e.target.value)} />
                                    </div>


                                    <div style={{ width: '100%', marginTop: "20px", display: 'flex', flexDirection: 'column', alignItems: 'baseline', textAlign: 'left' }}>
                                        <leble>Answer :</leble>
                                        <textarea style={{ width: '95%' }} value={inputanser} onChange={(e) => setinputanser(e.target.value)} />
                                    </div>

                                    <div style={{ width: '100%', marginTop: "20px", display: 'flex', justifyContent: 'space-between' }}>
                                        <button className='cnclbtn' style={{ border: '1px solid red', backgroundColor: '#fff', color: 'red' }} onClick={() => {
                                            setpopup(false)
                                            setinportquestion("")
                                            setinputanser("")
                                        }}>Cancel</button>
                                        <button className='button'>Submit</button>
                                    </div>
                                </form>
                            </div>

                        </>}


                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                            <div className='addbutton'>


                                <button onClick={() => {
                                    setpopup(true)

                                }}><BiSolidAddToQueue style={{ width: '20px', height: '20px' }} />Add Question</button></div> </>}
                        <div className='header' >
                            <h2>FAQs </h2>
                        </div>
                        <div className='questionbox'>
                            <h3 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>FAQs (For Website Help Center)</h3>
                            {question.map((value, index) => {

                                return (<>
                                    <div className='question'>
                                        <h4 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}>{index + 1} -</h4> {value.question}</h4>
                                        <p style={{ display: 'flex', textAlign: 'left' }}>
                                            <h4 style={{ width: '30px' }}></h4>
                                            <span dangerouslySetInnerHTML={{ __html: value.answer.replace(/\n/g, "<br />") }} />
                                        </p>    <div className='dltbtndiv'> <button className='dltbtn' onClick={async () => {
                                            const hide = message.loading("Action in progress", 0)
                                            var myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                            var urlencoded = new URLSearchParams();
                                            urlencoded.append("_id", value._id);

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: urlencoded,
                                                redirect: 'follow'
                                            };


                                            await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltfaq", requestOptions)
                                                .then(response => response.json())
                                                .then(result => {
                                                    setquestion(result)


                                                })
                                                .catch(error => console.log('error', error));





                                            await setTimeout(() => {
                                                hide(); // Call hide to stop the loading message
                                                message.success("Action completed successfully");
                                            }, 2000);
                                        }}>Detele This Question</button> </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default Helpcenter
