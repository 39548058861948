import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import { MdOutlineAnalytics } from 'react-icons/md';
import { message } from 'antd';

function KitAnalysis() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const [kit, setkit] = useState([])
    const [kit2, setkit2] = useState([])
    const [practitioner, setpractitioner] = useState([])

    useEffect(() => {

        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



            var requestOptions = {
                method: 'GET',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmicrokits", requestOptions)
                .then(response => response.json())
                .then(result => setkit(result))

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
                .then(response => response.json())
                .then(result => setkit2(result))

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
                .then(response => response.json())
                .then(result => setpractitioner(result))


        }






    }, [])


    // const dooption = () => {
    //     kit.map((value, index) => {

    //         // options = [
    //         //   { value: 'Burns Bay Road' },

    //         // ];
    //         // const newvalue = toString(value.kitid)
    //         options.push({ value: value.kitid })


    //     })


    // }


    // const [searchdone, setsearchdone] = useState(false)



    // const clearsearch = () => {
    //     const hide = message.loading("Action in progress", 0)
    //     if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

    //         var myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    //         var requestOptions = {
    //             method: 'GET',
    //             headers: myHeaders,

    //             redirect: 'follow'
    //         };

    //         fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
    //             .then(response => response.json())
    //             .then(result => setkit(result))



    //         var myHeaders2 = new Headers();
    //         myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



    //         var requestOptions2 = {
    //             method: 'GET',
    //             headers: myHeaders2,

    //             redirect: 'follow'
    //         };

    //         fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
    //             .then(response => response.json())
    //             .then(result => {
    //                 setpractitioner(result)
    //                 setpractitioner2(result)
    //             })





    //     } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

    //         var urlencoded2 = new URLSearchParams();
    //         urlencoded2.append("id", id);

    //         var requestOptions2 = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: urlencoded2,
    //             redirect: 'follow'
    //         };


    //         fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
    //             .then(response => response.json())
    //             .then(result => setkit(result))
    //             .catch(error => console.log('error', error));




    //     }

    //     setsearchdone(false)
    //     setTimeout(() => {
    //         hide(); // Call hide to stop the loading message
    //         message.success("Action completed successfully");
    //     }, 2000);
    // }

    var sno1 = 1
    var sno2 = 1

    return (
        <>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

                <Navbar />
                <div className='deshboardmain'>

                    <Sidemanu />

                    <div className='adminkitmainbody'>

                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><MdOutlineAnalytics style={{ width: '50px', height: '50px' }} />Kit Analysis</h1>
                            {/* {dooption()}
                            {searchdone === false && <>
                                <form onSubmit={handleInputChange}>
                               

                                    <AutoComplete
                                        type="number"
                                        style={{ width: 200 }}
                                        options={options}
                                        placeholder="Search by Kit ID"
                                        filterOption={(inputValue, options) =>
                                            options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            //  console.log(kitss) 
                                        }
                                        onChange={(inputValue) => setSearchTerm(inputValue)}
                                    />
                                    <button>Search</button>
                                </form>
                            </>}
                            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
                            </>} */}
                        </div>
                        <div className='imp'>


                            <h3 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>Microbiome Test</h3>

                            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>

                                <thead className='tablephead'>
                                    <tr>
                                        <th>S NO.</th>
                                        <th>KIT ID</th>
                                        <th>Practitioner</th>
                                        <th>Data</th>

                                        <th>Patient Name</th>
                                        <th>Action</th>
                                    </tr>


                                </thead>
                                <tbody>

                                    {kit.map((item2, index) => {
                                        return (<>

                                            <tr>
                                                <td>{sno1++}</td>

                                                <td>{item2.KitID}</td>

                                                <td style={{ display: "flex", flexDirection: 'column', textAlign: 'left', }}>
                                                    <td style={{ border: "none", width: '50%' }}>{item2.practitioner} </td>
                                                    <td style={{ border: "none", width: '50%' }}>{item2.practitioner_email}</td>

                                                </td>

                                                <td>{item2.date}</td>

                                                <td>{item2.patient}</td>


                                                <td>
                                                    {item2.emailnoti === true ? (<> <button className='button' style={{ backgroundColor: '#fff', color: '#7FA2C4', border: '1px solid #7FA2C4' }} onClick={() => {
                                                        message.error("The email has already been dispatched.")
                                                    }}
                                                    >Already sent</button></>) : (<>
                                                        <button className='button'
                                                            onClick={() => {
                                                                const hide = message.loading("Action in progress", 0)

                                                                const myHeaders = new Headers();
                                                                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                                                const urlencoded = new URLSearchParams();
                                                                urlencoded.append("_id", item2._id);

                                                                const requestOptions = {
                                                                    method: "POST",
                                                                    headers: myHeaders,
                                                                    body: urlencoded,
                                                                    redirect: "follow"
                                                                };

                                                                fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sent6monthemailpra", requestOptions)
                                                                    .then((response) => response.json())
                                                                    .then((result) => setkit(result))
                                                                    .catch((error) => console.error(error));

                                                                setTimeout(() => {
                                                                    hide(); // Call hide to stop the loading message
                                                                    message.success("Action completed successfully");
                                                                }, 2000);
                                                            }}
                                                        > Send Email</button>
                                                    </>)}




                                                </td>
                                            </tr>
                                        </>)
                                    })}


                                </tbody>
                            </table>
                        </div>

                        <div style={{ marginTop: '50px' }}></div>
                        <div className='imp'>


                            <h3 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}> Food Sensitivity Test</h3>

                            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>

                                <thead className='tablephead'>
                                    <tr>
                                        <th>S NO.</th>
                                        <th>KIT ID</th>
                                        <th>Practitioner</th>
                                        <th>Data</th>

                                        <th>Patient Name</th>
                                        <th>Action</th>
                                    </tr>


                                </thead>
                                <tbody>

                                    {kit2.map((item2, index) => {

                                        var show = false


                                        if (item2.Kittype === "100" || item2.Kittype === "210") {

                                            const providedDateObj = new Date(item2.resultDate);
                                            const currentDate = new Date();
                                            // Get the current date
                                            const sixMonthsAgo = new Date();
                                            sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

                                            if (providedDateObj < sixMonthsAgo) {

                                                show = true

                                            }





                                        }


                                        return (<>
                                            {show === true && <>
                                                <tr>
                                                    <td>{sno2++}</td>

                                                    <td>{item2.kitid}</td>

                                                    <td style={{ display: "flex", justifyContent: 'space-between',alignItems:'center',flexDirection:"column" }}>
                                                        {practitioner.map((item22, index) => (<>

                                                            {item22._id === item2.assignedto && <>

                                                                <td style={{ border: "none", width: '50%' }}>{item22.name} </td>
                                                                <td style={{ border: "none", width: '50%' }}>{item22.email}</td>
                                                            </>}


                                                        </>))}
                                                    </td>

                                                    <td>{item2.resultDate}</td>

                                                    <td>{item2.patientName}</td>


                                                    <td>
                                                        {item2.emailnoti === true ? (<> <button className='button' style={{ backgroundColor: '#fff', color: '#7FA2C4', border: '1px solid #7FA2C4' }} onClick={() => {
                                                            message.error("The email has already been dispatched.")
                                                        }}
                                                        >Already sent</button></>) : (<>
                                                            <button className='button'
                                                                onClick={() => {
                                                                    const hide = message.loading("Action in progress", 0)

                                                                    const myHeaders = new Headers();
                                                                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                                                    const urlencoded = new URLSearchParams();
                                                                    urlencoded.append("_id", item2._id);

                                                                    const requestOptions = {
                                                                        method: "POST",
                                                                        headers: myHeaders,
                                                                        body: urlencoded,
                                                                        redirect: "follow"
                                                                    };

                                                                    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sent6monthemailfs", requestOptions)
                                                                        .then((response) => response.json())
                                                                        .then((result) => setkit2(result))
                                                                        .catch((error) => console.error(error));

                                                                    setTimeout(() => {
                                                                        hide(); // Call hide to stop the loading message
                                                                        message.success("Action completed successfully");
                                                                    }, 2000);
                                                                }}
                                                            > Send Email</button>
                                                        </>)}




                                                    </td>
                                                </tr></>}
                                        </>)
                                    })}


                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>




            </>}

        </>
    )
}

export default KitAnalysis
