import React, { useState, useRef, useEffect } from 'react'
import Cookies from 'js-cookie';
import Navbar from '../components/navbar';
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import { ImUpload2 } from 'react-icons/im'
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { message } from 'antd';

function Bucket() {
    const [csvFile, setCSVFile] = useState([]);
    const fileInputRef = useRef(null);


    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            setCSVFile(prevFiles => [...prevFiles, ...Array.from(droppedFiles)]);
        } else {
            alert('Please drop valid files.');
        }
    };



    const handleFileUpload = async (event) => {




        const files = event.target.files;

        console.log(event.target.files)
        // console.log(csvFile)
        setCSVFile(prevFiles => [...prevFiles, ...Array.from(files)]);





    };




    const datauploaded = async (e) => {
        e.preventDefault()
        if (csvFile) {

            const hide = message.loading("Action in progress", 0)

            {
                csvFile.map(async (file, index) => {


                    const ex = file.name.split('.')[1]
                    const ex2 = file.name.split('.')[0]
                    const newwkid = ex2


                    const Kittt = newwkid.replace(/\D/g, '');

                    const kitiddd = parseInt(Kittt)




                 

                    var imgRef
                    if (newwkid.includes("RD00")) {
                        imgRef = ref(imageDb, `dnamap/DNAMap result RD00${kitiddd}.${ex}`)
                      

                    } else {
                        const alphabets = newwkid.replace(/[^a-zA-Z]/g, '');

                        imgRef = ref(imageDb, `Parasitology/Parasitology result ${alphabets}${kitiddd}.${ex}`)
                  
                    }

                    await uploadBytes(imgRef, file)
                    const cvUrl = await getDownloadURL(imgRef);






                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                    var urlencoded2 = new URLSearchParams();
                    urlencoded2.append("result", cvUrl);
                    urlencoded2.append("id", kitiddd);

                    var requestOptions2 = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded2,
                        redirect: 'follow'
                    };


                    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/uploadresultother", requestOptions2)
                        .then(response => response.json())
                        .then(result => {
                            message.success("Results Are Upload")
                        }
                        )
                        .catch(error => console.log('error', error));

                })
            }





            await setCSVFile([])

            await setTimeout(() => {
                hide(); // Call hide to stop the loading message
                message.success("Action completed successfully");
            }, 2000);


        }

        else { message.error("please upload a file first") }
    }

    return (<>


        <Navbar />
        <div className='deshboardmain'>
            <div className='popup' style={{ height: 'fit-contant', maxHeight: "82vh", backgroundColor: '#fdgh' }}>

                <h4 style={{ color: '#7050A0' }} align="left">Please note that only Parasitology Test and DNAMap Results are acceptable.</h4>

                <div className="file-drop-zone"


                    onDrop={handleFileDrop}
                    onDragOver={(e) => e.preventDefault()}

                >

                    <label className="custom-file-upload" >

                        <div className='clickablediv'>


                            <ImUpload2 style={{ width: '100px', height: '100px' }} />
                            <h4>Click or drag file to this area to upload</h4>


                        </div>
                        <input
                            multiple="off"
                            ref={fileInputRef}
                            className='inputbuttontouploadfile'
                            type="file"
                            accept=".pdf"
                            onChange={handleFileUpload}
                        />

                    </label>

                </div>

                {csvFile.length > 0 && (
                    <div>
                        <h3>Uploaded Files:</h3>
                        <ul>
                            {csvFile.map((file, index) => (
                                <div className="filename" key={index}>
                                    <p>{file.name}</p>
                                    <AiFillDelete
                                        onClick={() => {

                                            const updatedFiles = csvFile.filter((_, i) => i !== index);
                                            setCSVFile(updatedFiles);

                                        }}
                                        className="hovar"
                                        style={{ width: '30px', height: '30px', color: 'red' }}
                                    />
                                </div>
                            ))}
                        </ul>
                    </div>
                )}







                <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }} onSubmit={datauploaded}>
                    {/* <input placeholder="Enter Result's Kit ID" required onChange={(e) => { setkitiddd(e.target.value) }} value={kitiddd} /> */}


                    <div className='bownpopupbutton' style={{ width: '100%' }}>
                        <button onClick={() => {

                            setCSVFile([])
                        }
                        } style={{ border: '1px solid red', color: 'red', backgroundColor: '#fff' }} >Clear</button>
                        <button type='submit' style={{ backgroundColor: '#4180b7' }}>Upload</button>
                    </div>

                </form>







            </div>
        </div>

    </>)
}

export default Bucket