
import React, { useState } from 'react'
import Cookies from 'js-cookie';
import { BiHelpCircle, BiSolidAddToQueue } from 'react-icons/bi';
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/helpcenter.css"
import { useEffect } from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';



function Tickets() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const [question, setquestion] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



            var requestOptions = {
                method: 'GET',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getalltickets", requestOptions)
                .then(response => response.json())
                .then(result => setquestion(result))
        }

    }, [])


    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <Navbar />
                <div className='deshboardmain'>
                    <Sidemanu />
                    <div className='adminkitmainbody'>
                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><BiHelpCircle style={{ width: '50px', height: '50px' }} />Help Center</h1>
                        </div>






                        <div className='header' >
                            <h2>Tickets </h2>
                        </div>
                        <div className='questionbox'>
                            <h3 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>Tickets (From Website Help Center)</h3>
                            {question.map((value, index) => {

                                return (<>
                                    <div className='question'>
                                        <h4 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}>{index + 1} -</h4> {value.subject}</h4>
                                        <h5 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}></h4> priority: {value.priority}</h5>

                                        <p style={{ display: 'flex', textAlign: 'left' }}>
                                            <h4 style={{ width: '30px' }}></h4>
                                            <span dangerouslySetInnerHTML={{ __html: value.Message.replace(/\n/g, "<br />") }} />
                                        </p>    <div className='dltbtndiv'> <button className='button' onClick={async () => {

                                            navigate("/dashboard/tickets/"+value._id)
                                        }}>Inquiry</button> </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default Tickets
