import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import Navbar from '../components/navbar';
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import { message } from 'antd';


function Ackpage() {


    const [addkit2, setaddkit2] = useState("")
    const [addkit2p, setaddkit2p] = useState("")
    const [addkit, setaddkit] = useState([])


    const [prefix, setprefix] = useState("PGM")




    const Addkit = async (e) => {
        await e.preventDefault()


        await setaddkit([...addkit, { Kit: addkit2, Name: addkit2p }]);
        setaddkit2("")
        setaddkit2p("")


        console.log(addkit)
    }


    const RemoveItemByIndex = (index) => {
        // Create a new array without the item at the specified index
        const updatedAddkit = addkit.filter((_, i) => i !== index);
        setaddkit(updatedAddkit);
    };




    const sendnewkittobackend = async () => {
        const hide = message.loading("Action in progress", 0)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json"); // Change the content type to JSON

        // Create an array


        // Convert the array to a JSON string
        var jsonData = JSON.stringify(addkit);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: jsonData, // Send the JSON data
            redirect: 'follow'
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addackkkkit", requestOptions)
            .then(response => response.text())
            .then(result => {
                setaddkit([])
                setaddkit2("")
                // message.success(result)
            }


            )
            .catch(error => console.log('error', error));



            await setTimeout(() => {
                hide(); // Call hide to stop the loading message
                message.success("Action completed successfully");
              }, 2000);

    }


var sno = 1
    return (<>


        <Navbar />
        <div className='deshboardmain'>
            <div className='popup'>
                <form onSubmit={Addkit}>

                    <input onChange={(e) => setaddkit2(e.target.value)} value={addkit2} required placeholder='Enter the Kit ID' />

                    <input onChange={(e) => setaddkit2p(e.target.value)} value={addkit2p} required  placeholder='Enter Patient Name' />

                    <button >Add</button>
                </form>


                {addkit.length !== 0 && <>

                    <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                        <thead className='tablephead'>
                            <tr>
                                <th>S NO.</th>
                                <th>Kit ID</th>
                                <th>Patient Name</th>

                                <th></th>


                            </tr>
                        </thead>
                        <tbody>
                            {console.log(addkit)}
                            {addkit.map((kit, index) => (

                                <tr>
                                    <td>{sno++}</td>

                                    <td>{kit.Kit} </td>
                                    <td>{kit.Name} </td>

                                    <td className='assignbuttom' ><AiFillDelete className='hovar' onClick={() => RemoveItemByIndex(index)} style={{ width: '30px', height: '30px', color: 'red' }} /></td>

                                </tr>
                            ))}










                        </tbody>
                    </table>
                </>}

                <div className='bownpopupbutton'>
                    <button onClick={() => {

                        setaddkit([])
                        setaddkit2("")
                    }
                    } style={{ border: '1px solid red', color: 'black' }} >Clear</button>


                    <button onClick={sendnewkittobackend} style={{ backgroundColor: '#4180b7' }}>Submit</button>
                </div>
            </div>
        </div>

    </>)
}

export default Ackpage