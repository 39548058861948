import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import "../css/webinar.css"
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { message } from 'antd';


function Meeting() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const idd = Cookies.get("id")
    const token = Cookies.get("Token")

    const { id } = useParams();
    const [meeting, setmeeting] = useState([])

    useEffect(() => {
        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();
            urlencoded.append("URL", id);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getalleventbyurl", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setmeeting(result)

                    if (result.TaskId) {
                        setrecording(true)
                    }
                })
                .catch(error => console.log('error', error));

        }
    }, [])


    const myMeeting = async (element) => {


        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
            parseInt("1426619796"),
            "224cefed6979db88285a5c524756adf4",
            id,//meeting id
            "sdfs34", //for test
            "YouGutMap",//for test
            // user?.uid ? user.uid : generateMeetingID(),
            // user?.displayName ? user.displayName : generateMeetingID()
        );
        const zp = ZegoUIKitPrebuilt.create(kitToken);

        zp?.joinRoom({
            container: element,
            showPreJoinView: false,
            showScreenSharingButton: true,
            showRemoveUserButton: true,
            showRoomTimer: true,

            sharedLinks: [
                {
                    name: meeting.title,
                    url: "https://academy.yourgutmap.co.uk/" + id,
                },
            ],
            scenario: {
                mode: "LiveStreaming",
                config: {
                    role: "Host",
                }
            },
        });



    };


    const [recording, setrecording] = useState(false)


    const startrecordong = async () => {

        const hide = message.loading("Action in progress", 0)
  
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("roomid", id);
        urlencoded.append("_id", meeting._id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

     await   fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/startrecordinginroom", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setmeeting(result)
                if (result.TaskId) {
                    setrecording(true)
                    setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Recording Started successfully");
                      }, 2000);

                }
            })
            .catch((error) => console.error(error));
            setTimeout(() => {
                hide(); // Call hide to stop the loading message
              
              }, 2000);
    }


    const stoprecordong = async () => {

        const hide = message.loading("Action in progress", 0)
  
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("TaskId", meeting.TaskId);
        urlencoded.append("_id", meeting._id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

     await   fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/stoprecordinginroom", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setmeeting(result)
                if (!result.TaskId) {
                    setrecording(false)
                    setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Recording Stoped successfully");
                      }, 2000);

                }
            })
            .catch((error) => console.error(error));
            setTimeout(() => {
                hide(); // Call hide to stop the loading message
              
              }, 2000);
    }

    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <Navbar />
                <div className='deshboardmain'>


                    <div className='dIzgYQV4CBbzZxzJbwbS meradiv'>
                        {recording ? (<>
                            <button className='VFa21T9RLFCP0goDE198 eHWwrEDF57fbHO0pxJYw'  onClick={stoprecordong}>Stop Recording</button>
                        </>) : (<>
                            <button className='VFa21T9RLFCP0goDE198' onClick={startrecordong}>Start Recording</button>
                        </>)}
                    </div>
                    <div
                        className="myCallContainer"
                        ref={myMeeting}
                        style={{ width: "100%", height: "90vh" }}
                    >


                    </div>










                </div>
            </>}
        </div>
    )
}

export default Meeting
