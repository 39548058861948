import React from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { AutoComplete } from 'antd'

function Mircoreport() {
  return (
    <div>
      
      <Navbar/>
      
      <div className='deshboardmain'>
      <Sidemanu />
      <div className='profilemainbody'>
     
      <div className='header'>
            <h1 className='pppheading'>  Microbiome Reports</h1>
           

           
              <form >
                <AutoComplete
                
                  placeholder="Search by Email/Name"
                 
                />
                <button>Search</button>
              </form>

          
            
          </div>

      </div>


      </div>
      
     </div>
  )
}

export default Mircoreport